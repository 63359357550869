import React, { useState,useEffect } from 'react';
import './crypt.css'; // Import your CSS file for styling
import partner from '../images/partner.png';
import simplex from '../images/simplex.png';
import Qrcd from './Qrcd';
import { Button, Form, Input } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import Drop from './Drop';
import {  Dropdown, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import tsd from '../images/tsd.png'
import axios from 'axios';
const { Search } = Input;
const CryptoPage = () => {
  const [filePath, setFilePath] = useState(null);
  const [fileObj, setFileObj] = useState(null);
  const [buttonClicked, setButtonClicked] = useState(null);
  const [buttonStyle, setButtonStyle] = useState({});
  const storedEmail = localStorage.getItem("username"); // Retrieve stored email from localStorage
  const [usdtValue, setUsdtValue] = useState('');
  const [usdtReceived, setUsdtReceived] = useState();
  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={() => handleMenuClick("Tether USD (USDT)")}><img src={tsd} alt="Icon"  style={{width:'20px',height:'20px'}}/>Tether USD (USDT)</Menu.Item>
      <Menu.Item key="2" onClick={() => handleMenuClick("Tether USD (USDT-SC)")}><img src={tsd} alt="Icon"  style={{width:'20px',height:'20px'}}/>Tether USD (USDT-SC)</Menu.Item>
      <Menu.Item key="3" onClick={() => handleMenuClick("Tether USD (USDT-TRC20)")}><img src={tsd} alt="Icon"  style={{width:'20px',height:'20px'}}/>Tether USD (USDT-TRC20)</Menu.Item>
    </Menu>
  );
  const [selectedOption, setSelectedOption] = useState("Tether USD (USDT)");
  const [data1, setData1] = useState('');
  const [data2, setData2] = useState('');
  const [data3, setData3] = useState('');
  useEffect(() => {
    fetchData('https://apextrade.pythonanywhere.com/firstapi', setData1);
    fetchData('https://apextrade.pythonanywhere.com/secondapi', setData2);
    fetchData('https://apextrade.pythonanywhere.com/thirdapi', setData3);
  }, []);
const fetchData = async (apiEndpoint, setData) => {
    try {
      const response = await axios.get(apiEndpoint);
      debugger
      console.log("data value",response.data.message)
      setData(response.data.message);
    } catch (error) {
      console.error(error);
    }
  };
const usdtReceivedValues = {
  "Tether USD (USDT)": usdtValue * Number(data1), // Assuming 20 is a constant multiplier
  "Tether USD (USDT-SC)": usdtValue * Number(data2), // Assuming 30 is a constant multiplier
  "Tether USD (USDT-TRC20)": usdtValue *Number(data3), // Assuming 40 is a constant multiplier
};
useEffect(() => {
  console.log("Component re-rendered");
}, [usdtReceived]); // Add other relevant dependencies as needed

useEffect(() => {
  console.log("Component re-rendered");
}, [usdtReceived]); // Add other relevant dependencies as needed

const handleMenuClick = (option) => {
  debugger
setSelectedOption(option);
setUsdtReceived(usdtReceivedValues[option]);
};

useEffect(() => {
  console.log("Component re-rendered");
}, [usdtReceived]); // Add other relevant dependencies as needed

  const onFinish = (values) => {
    console.log('Received values of form: ', values);
    // Add your form submission logic here

    // Construct form data
    const formData = new FormData();
    formData.append('cryptoType', buttonClicked === 'green' ? 'buy' : 'sell');
    formData.append('usdtValue', values.usdtValue);
    formData.append('UsdtRecieved', values.UsdtRecieved);
    formData.append('PaymentImage', fileObj);
    formData.append('email', storedEmail);

    // Send the request
    fetch('https://apextrade.pythonanywhere.com/usdtpost/', {
      method: 'POST',
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        window.location.href = '/success';
      })
      .catch(error => console.error('Error:', error));
  };

  const handleButtonClick = (color) => {
    setButtonClicked(color);
    if (color === 'green') {
      setButtonStyle({ width: '120px', fontSize: '18px' }); // Adjust the width and font size for the "Buy" button
    } else if (color=="red") {
      setButtonStyle({ width: '120px', fontSize: '18px' }); // Reset the style to normal for the "Sell" button
    }else{
      setButtonStyle({ });
    }
  };

  return (
    <center className='p-2' style={{ backgroundImage: 'url("https://i.makeagif.com/media/12-04-2018/IxHq7M.gif")', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
     
      <h2 className="mt-3" style={{ fontWeight: 'bold', fontSize: '24px', color: '#333' }}>Buying Crypto Was Never This Easy</h2>
      <div className='col-lg-4 mt-5 p-2 crypto-form' style={{ backgroundColor: '#f0f0f0', borderRadius: '10px', boxShadow: '0 2px 5px rgba(0,0,0,0.1)' }}>
        <div className="buttons-container" style={{ marginBottom: '20px' }}>
        <Button
  type="primary"
  className={`buy-button ${buttonClicked === 'green' ? 'clicked' : ''}`}
  onClick={() => handleButtonClick('green')}
  style={{ ...buttonStyle, width: buttonClicked === 'green' ? '200px' : 'auto', height: buttonClicked === 'green' ? '50px' : 'auto' }}
>
  Buy
</Button>
<Button
  type="primary"
  className={`sell-button ${buttonClicked === 'red' ? 'clicked' : ''}`}
  onClick={() => handleButtonClick('red')}
  style={{ ...buttonStyle, width: buttonClicked === 'red' ? '200px' : 'auto', height: buttonClicked === 'red' ? '50px' : 'auto' }}
>
  Sell
</Button>
        </div>
        <h4 style={{ fontWeight: 'bold', fontSize: '18px', color: '#333' }} className='mt-2'>Buy with Debit or Credit Card</h4>
        <p><small><b>Powered By</b></small>&nbsp;&nbsp;<span><img src="logo.png" style={{ maxWidth: ' 7rem', height: '40px' }} /></span></p>
        <Form
          name="normal_login"
          className="login-form mt-3 p-2"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
          <Form.Item
            name="usdtValue"
            rules={[
              {
                required: true,
                message: 'Please input the USDT value!',
              },
            ]}
          >
            <div className='d-flex justify-content-between p-1' style={{ border: '1px solid #00000054', borderRadius: '5px' }}>
              <Input placeholder="USDT Value" style={{ border: '1px solid white' }}  value={usdtValue}
                onChange={(e) => setUsdtValue(e.target.value)} />
              <Dropdown overlay={menu} placement="bottomRight">
                <Button style={{ border: '1px solid white',padding:"21px" }}>
                  {selectedOption}<DownOutlined />
                </Button>
              </Dropdown>
            </div>
          </Form.Item>
          <input type="text" placeholder="Received USDT Value" value={usdtReceivedValues[selectedOption]} onChange={(e) => setUsdtReceived(e.target.value)} />
          {/* <Form.Item
  name="UsdtRecieved"
  
  rules={[
    {
      required: true,
      message: 'Please input the received USDT value!',
    },
  ]}
>
  
  <input type="text" placeholder="Received USDT Value" value={usdtReceivedValues[selectedOption]} onChange={(e) => setUsdtReceived(e.target.value)} />
</Form.Item> */}

          <Form.Item
            name="USDaddress "
            rules={[
              {
                required: true,
                message: 'USD(USDT) address',
              },
            ]}
          >
            <Input placeholder="USD(USDT) address" />
          </Form.Item>
          <Qrcd />
          <p className='mt-3'>Please upload your file here</p>
          <div>
            <label>Image upload</label> &nbsp;&nbsp;
            <input
              required
              className="mb-3"
              type="file"
              onChange={(e) => {
                setFileObj(e.target?.files[0]);
                setFilePath(e.target.value);
                console.log("change", e.target?.files[0].name, e.target.value);
              }}
            />
          </div>
          <Form.Item>
            <Button type="primary" htmlType="submit" className="mt-3 w-100 text-white" style={{ border: '1px solid black', backgroundColor: buttonClicked, fontWeight: 'bold', padding: '20px' }}>
              <p style={{ marginTop: '-10px' }}>CONTINUE</p>
            </Button>
          </Form.Item>
          <a href='/' className='text-black' style={{ fontSize: '16px', color: '#333' }}>Learn more about APEX Trade</a>
          <p style={{ fontSize: '16px', color: '#333' }}><span style={{ color: 'gray' }}>Need support?</span> <a href='/' className='text-black'>Contact support@apextrade.com</a></p>
        </Form>
      </div>
    </center>
  );
};


export default CryptoPage;
