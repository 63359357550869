import React, { useState, useEffect } from 'react';
import './Header.css';
import { Button } from 'antd';

const Header = () => {
    const [username, setUsername] = useState('');

    useEffect(() => {
        const storedUsername = localStorage.getItem('username');
        if (storedUsername) {
            setUsername(storedUsername);
        }
    }, []);

    const handleLogout = () => {
        localStorage.removeItem('username');
        window.location.href = '/login'; // Redirect to login page after logout
    };

    return (
        <div className="navbar bg-color-black w-nav newone" role="banner">
            <div className="container-large-navbar">
                <a href="/" className="brand w-nav-brand" aria-label="home">
                    <img src="logo.png" loading="lazy" width={119} alt="Ramp logo" />
                </a>
                {username ? (
                    <>
                        <span className="nav-link white">{username}</span>
                        <Button type="primary" onClick={handleLogout} className="custom-button mx-2">Logout</Button>
                    </>
                ) : (
                    <>
                        <a href="/login" className="nav-link white w-nav-link">Login</a>
                        <a href="/signup" className="nav-link white w-nav-link">Signup</a>
                    </>
                )}
                <div className="">
                    <a id="ramp-widget-mobile" href="/buy" className="button nav-mobile show-mobile w-button">BUY &amp; SELL </a>
                    <a href="/buy" aria-current="page" className="button nav-mobile hide-mobile w-button w--current">BUY &amp; SELL </a>
                    <div className="menu-button w-nav-button">
                        <img src="https://assets-global.website-files.com/63fe1b7ead2cd2d5e0af02e7/645e7d4205b3d28f4838a3d6_Frame%2048095674.webp" loading="eager" width={32} alt="Hamburger menu white" className="nav-white-hamburger" />
                    </div>
                </div>
            </div>
            <div className="w-nav-overlay" data-wf-ignore="" id="w-nav-overlay-0" />
        </div>
    );
};

export default Header;
