import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import SuccesssPage from './pages/SuccessPage';
import Signup from './accounts/Signup';
import Login from './accounts/Login';
import Nav1 from './components/Nav1';
import CryptoPage from './pages/CryptoPage';
import Home from './components/Home/Home';
import Header from './components/Header/Header';
import AdminPanel from './components/Admin/Admin';



function App() {
    return (
        <div className="App">
         
                <BrowserRouter>
               <Header/>
                    <Routes>
                        <Route path="/signup" element={<Signup/>}></Route> {/*Done*/}
                        <Route path="/login" element={<Login />}></Route>   {/*Done*/}
                        <Route path="/buy" element={<CryptoPage/>}></Route> {/*Done*/}
                        <Route path="/" element={<Home/>}></Route>
                        <Route path="/success" element={<SuccesssPage/>}></Route> {/*Done*/}
                        <Route path="/admin" element={<AdminPanel/>}></Route>
                    </Routes>
                </BrowserRouter>
            
        </div>
    );
}

export default App;

