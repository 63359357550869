import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input } from 'antd';
import React from 'react';
import "./home.css"

const Home = () => {


  return (
    <div className="wrapper">
      {/* <div id="loader-wrapper">
      <div id="loader" />
      <div className="loader-section section-left" />
      <div className="loader-section section-right" />
    </div> */}
      {/* /.End of loader wrapper*/}

      {/* /. End of Navigation */}
      <div
        className="animation-slide owl-carousel owl-theme owl-loaded owl-drag"
        id="animation-slide"
      >
        {/* Slide 1*/}
        {/* Slide 2*/}
        {/* Slide 3*/}
        <div className="owl-stage-outer">
          <div
            className="owl-stage"
            style={{
              transform: "translate3d(-5396px, 0px, 0px)",
              transition: "all 0.25s ease 0s",
              width: 9443
            }}
          >
            <div className="owl-item cloned" style={{ width: 1349 }}>
              <div className="item slide-two">
                <div className="slide-table">
                  <div className="slide-tablecell">
                    <div className="container">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="slide-text">
                            <h2 style={{ opacity: 0 }}>
                              Bitcoin{" "}
                              <span className="bright-turquoise">Exchange</span>{" "}
                              <br />
                              You Can Trust
                            </h2>
                            <p style={{ opacity: 0 }}>
                              Miker Ipsum is simply dummy text of the printing and
                              typesetting industry.
                              <br /> Lorem Ipsum has been the industry's standard
                              dummy text ever since the 1500s
                            </p>
                            <div className="slide-buttons" style={{ opacity: 0 }}>
                              <a href="#" className="slide-btn">
                                Download Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="owl-item cloned" style={{ width: '100%', maxWidth: '1349px' }}>
              <div className="item slide-three">
                <div className="slide-table">
                  <div className="slide-tablecell">
                    <div className="container">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="slide-text text-center">
                            <h2 style={{ opacity: 0 }}>
                              Take the world's best <br />
                              <span className="navy-blue">Cryptocurrency</span>{" "}
                              Site.
                            </h2>
                            <p style={{ opacity: 0 }}>
                              Miker Ipsum is simply dummy text of the printing and
                              typesetting industry. <br />
                              Lorem Ipsum has been the industry's standard dummy
                              text ever since the 1500s
                            </p>
                            <div className="slide-buttons" style={{ opacity: 0 }}>
                              <a href="#" className="slide-btn">
                                Start Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="owl-item" style={{ width: 1349 }}>
              <div className="item slide-one">
                <div className="slide-table">
                  <div className="slide-tablecell">
                    <div className="container">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="slide-text text-center">
                            <h2 style={{ opacity: 0 }}>
                              The Feature of{" "}
                              <span className="outrageous-orange">Financing</span>{" "}
                              <br />
                              Technology is Here{" "}
                            </h2>
                            <p style={{ opacity: 0 }}>
                              It is a long established fact that a reader will be
                              distracted by the readable content of a page when.
                              <br /> looking at its layout The point of using Lorem
                              Ipsum is that
                            </p>
                            <div className="slide-buttons" style={{ opacity: 0 }}>
                              <a href="#" className="slide-btn">
                                Get in touch
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="owl-item" style={{ width: 1349 }}>
              <div className="item slide-two">
                <div className="slide-table">
                  <div className="slide-tablecell">
                    <div className="container">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="slide-text">
                            <h2 style={{ opacity: 0 }}>
                              Bitcoin{" "}
                              <span className="bright-turquoise">Exchange</span>{" "}
                              <br />
                              You Can Trust
                            </h2>
                            <p style={{ opacity: 0 }}>
                              Miker Ipsum is simply dummy text of the printing and
                              typesetting industry.
                              <br /> Lorem Ipsum has been the industry's standard
                              dummy text ever since the 1500s
                            </p>
                            <div className="slide-buttons" style={{ opacity: 0 }}>
                              <a href="#" className="slide-btn">
                                Download Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v className="owl-item active" style={{ width: 1349 }}>
              <div className="item slide-three">
                <div className="slide-table">
                  <div className="slide-tablecell">
                    <div className="container">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="slide-text text-right">
                            <h2
                              style={{ opacity: 1 }}
                              className="fadeInUp animated"
                            >
                              Take the world's best <br />
                              <span className="navy-blue">Cryptocurrency</span>{" "}
                              Site.
                            </h2>
                            <p style={{ opacity: 1 }} className="fadeInUp animated">
                              Miker Ipsum is simply dummy text of the printing and
                              typesetting industry. <br />
                              Lorem Ipsum has been the industry's standard dummy
                              text ever since the 1500s
                            </p>
                            <div
                              className="slide-buttons fadeInUp animated"
                              style={{ opacity: 1 }}
                            >
                              <a href="#" className="slide-btn">
                                Start Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="owl-item cloned" style={{ width: 1349 }}>
              <div className="item slide-one">
                <div className="slide-table">
                  <div className="slide-tablecell">
                    <div className="container">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="slide-text text-center">
                            <h2 style={{ opacity: 0 }}>
                              The Feature of{" "}
                              <span className="outrageous-orange">Financing</span>{" "}
                              <br />
                              Technology is Here{" "}
                            </h2>
                            <p style={{ opacity: 0 }}>
                              It is a long established fact that a reader will be
                              distracted by the readable content of a page when.
                              <br /> looking at its layout The point of using Lorem
                              Ipsum is that
                            </p>
                            <div className="slide-buttons" style={{ opacity: 0 }}>
                              <a href="#" className="slide-btn">
                                Get in touch
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="owl-item cloned" style={{ width: 1349 }}>
              <div className="item slide-two">
                <div className="slide-table">
                  <div className="slide-tablecell">
                    <div className="container">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="slide-text">
                            <h2 style={{ opacity: 0 }}>
                              Bitcoin{" "}
                              <span className="bright-turquoise">Exchange</span>{" "}
                              <br />
                              You Can Trust
                            </h2>
                            <p style={{ opacity: 0 }}>
                              Miker Ipsum is simply dummy text of the printing and
                              typesetting industry.
                              <br /> Lorem Ipsum has been the industry's standard
                              dummy text ever since the 1500s
                            </p>
                            <div className="slide-buttons" style={{ opacity: 0 }}>
                              <a href="#" className="slide-btn">
                                Download Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="owl-nav">
          <div className="owl-prev">
            <i className="lnr lnr-chevron-left" />
          </div>
          <div className="owl-next">
            <i className="lnr lnr-chevron-right" />
          </div>
        </div>
        <div className="owl-dots">
          <div className="owl-dot">
            <span />
          </div>
          <div className="owl-dot">
            <span />
          </div>
          <div className="owl-dot active">
            <span />
          </div>
        </div>
      </div>
      {/* /.End of slider */}
      <div className="ticker">
        <div
          className="list-wrpaaer"
          style={{ position: "relative", overflow: "hidden" }}
        >
          <ul
            id="marquee-horizontal"
            style={{ position: "absolute", top: 0, left: "-651px", width: "1000%" }}
          >
            <li className="list-item">
              <div className="list-item-currency">BCCEUR </div>
              <div className="list-item-currency upgrade">
                <span>
                  1,244.26331 <span className="arrow-up">↗</span>
                </span>
              </div>
            </li>
            <li className="list-item">
              <div className="list-item-currency">BTCGBP</div>
              <div className="list-item-currency downgrade">
                <span>
                  7,088.48 <span className="arrow-down">↘</span>
                </span>
              </div>
            </li>
            <li className="list-item">
              <div className="list-item-currency">BCCUSD</div>
              <div className="list-item-currency downgrade">
                <span>
                  1,470 <span className="arrow-down">↘</span>
                </span>
              </div>
            </li>
            <li className="list-item">
              <div className="list-item-currency">BTCEUR</div>
              <div className="list-item-currency downgrade">
                <span>
                  8,444.84879 <span className="arrow-down">↘</span>
                </span>
              </div>
            </li>
            <li className="list-item">
              <div className="list-item-currency">BTCGBP</div>
              <div className="list-item-currency downgrade">
                <span>
                  7,088.48 <span className="arrow-down">↘</span>
                </span>
              </div>
            </li>
            <li className="list-item">
              <div className="list-item-currency">BTCRUB</div>
              <div className="list-item-currency upgrade">
                <span>
                  614,411.15205 <span className="arrow-up">↗</span>
                </span>
              </div>
            </li>
            <li className="list-item">
              <div className="list-item-currency">BTCUSD</div>
              <div className="list-item-currency downgrade">
                <span>
                  10,487.9123 <span className="arrow-down">↘</span>
                </span>
              </div>
            </li>
            <li className="list-item">
              <div className="list-item-currency">BTGBTC</div>
              <div className="list-item-currency downgrade">
                <span>
                  0.013 <span className="arrow-down">↘</span>
                </span>
              </div>
            </li>
            <li className="list-item">
              <div className="list-item-currency">BTGEUR</div>
              <div className="list-item-currency upgrade">
                <span>
                  118.91234 <span className="arrow-up">↗</span>
                </span>
              </div>
            </li>
            <li className="list-item">
              <div className="list-item-currency">BCCEUR </div>
              <div className="list-item-currency upgrade">
                <span>
                  1,244.26331 <span className="arrow-up">↗</span>
                </span>
              </div>
            </li>
            <li className="list-item">
              <div className="list-item-currency">BTCGBP</div>
              <div className="list-item-currency downgrade">
                <span>
                  7,088.48 <span className="arrow-down">↘</span>
                </span>
              </div>
            </li>
            <li className="list-item">
              <div className="list-item-currency">BCCUSD</div>
              <div className="list-item-currency downgrade">
                <span>
                  1,470 <span className="arrow-down">↘</span>
                </span>
              </div>
            </li>
            <li className="list-item">
              <div className="list-item-currency">BTCEUR</div>
              <div className="list-item-currency downgrade">
                <span>
                  8,444.84879 <span className="arrow-down">↘</span>
                </span>
              </div>
            </li>
            <li className="list-item">
              <div className="list-item-currency">BTCGBP</div>
              <div className="list-item-currency downgrade">
                <span>
                  7,088.48 <span className="arrow-down">↘</span>
                </span>
              </div>
            </li>
            <li className="list-item">
              <div className="list-item-currency">BTCRUB</div>
              <div className="list-item-currency upgrade">
                <span>
                  614,411.15205 <span className="arrow-up">↗</span>
                </span>
              </div>
            </li>
            <li className="list-item">
              <div className="list-item-currency">BTCUSD</div>
              <div className="list-item-currency downgrade">
                <span>
                  10,487.9123 <span className="arrow-down">↘</span>
                </span>
              </div>
            </li>
            <li className="list-item">
              <div className="list-item-currency">BTGBTC</div>
              <div className="list-item-currency downgrade">
                <span>
                  0.013 <span className="arrow-down">↘</span>
                </span>
              </div>
            </li>
            <li className="list-item">
              <div className="list-item-currency">BTGEUR</div>
              <div className="list-item-currency upgrade">
                <span>
                  118.91234 <span className="arrow-up">↗</span>
                </span>
              </div>
            </li>
          </ul>
        </div>
      </div>
      {/* /.End of tricker */}
      <div className="about_content">
        <div className="container">
          <div className="row about-text justify-content">
            <div className="col-md-6">
              <div className="about-info">
                <h2>What is ICO Crypto?</h2>
                <div className="definition">
                  ICO Crypto is a platform for the future of funding that powering
                  dat for the new equity blockchain
                </div>
                <p>
                  There are many variations of passages of Lorem Ipsum available,
                  but the majority have suffered alteration in some form, by
                  injected humour, or randomised words which don't look even
                  slightly believable. If you are going to use a passage of Lorem
                  Ipsum, you need to be sure there isn't anything.
                </p>
                <p>
                  All the Lorem Ipsum generators on the Internet tend to repeat
                  predefined chunks as necessary, making this the first true
                  generator.
                </p>
                <a href="#" className="btn btn-default mr-20">
                  Contact us
                </a>
                {/*<a href="#" class="btn btn-default-o mb-10">Our Service</a>*/}
                <div className="play-button">
                  <a
                    href="http://www.youtube.com/watch?v=0O2aH4XLbto"
                    className="btn-play popup-youtube"
                  >
                    <div className="play-icon">
                      <i className="fa fa-play" />
                    </div>
                    <div className="play-text">
                      <div className="btn-title-inner">
                        <div className="btn-title">
                          <span>Watch Video</span>
                        </div>
                        <div className="btn-subtitle">
                          <span>About Bitcoin</span>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="text-right">
                <img
                  src="http://cryptomarkethtml.bdtask.com/assets/img/about.jpg"
                  className="img-responsive"
                  alt=""
                />
              </div>
              <div className="quote">
                It is a long established fact that a reader will be distracted by
                the readable content of a page when looking at its layout.
                <div className="author-address">Tanjil Ahmed - Owner, Bdtask</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /.End of about content */}
      <div className="calculate">
        <div className="container">
          <div className="row">
            <div className="col-sm-8 col-sm-offset-2">
              <div className="section_title">
                <h3>
                  Quick Bitcoin <span>Converter</span>
                </h3>
                <p>
                  It is a long established fact that a reader will be distracted by
                  the readable content of a page when looking at its layout. The
                  point of using Lorem Ipsum.
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content">
            <div className="col-sm-4">
              <div className="bitcoin-sack">
                <img
                  src="http://cryptomarkethtml.bdtask.com/assets/img/calculate-img.png"
                  className="img-responsive center-block"
                  alt=""
                />
              </div>
            </div>
            <div className="col-sm-8">
              <div className="exchange-content">
                <form
                  className="form-inline exchange-form"
                  action="/action_page.php"
                >
                  <div className="input-group">
                    <div className="input-group-btn">
                      <div
                        className="btn-group bootstrap-select"
                        style={{ width: 80 }}
                      >
                        <button
                          type="button"
                          className="btn dropdown-toggle"
                          data-toggle="dropdown"
                          role="button"
                          title="BTC"
                        >
                          <span className="filter-option pull-left">BTC</span>&nbsp;
                          <span className="bs-caret">
                            <span className="caret" />
                          </span>
                        </button>
                        <div className="dropdown-menu open" role="combobox">
                          <ul
                            className="dropdown-menu inner"
                            role="listbox"
                            aria-expanded="false"
                            style={{ opacity: 1, display: "none" }}
                          >
                            <li data-original-index={0} className="selected">
                              <a
                                tabIndex={0}
                                className=""
                                data-tokens="null"
                                role="option"
                                aria-disabled="false"
                                aria-selected="true"
                              >
                                <span className="text">BTC</span>
                                <span className="glyphicon glyphicon-ok check-mark" />
                              </a>
                            </li>
                            <li data-original-index={1}>
                              <a
                                tabIndex={0}
                                className=""
                                data-tokens="null"
                                role="option"
                                aria-disabled="false"
                                aria-selected="false"
                              >
                                <span className="text">USD</span>
                                <span className="glyphicon glyphicon-ok check-mark" />
                              </a>
                            </li>
                            <li data-original-index={2}>
                              <a
                                tabIndex={0}
                                className=""
                                data-tokens="null"
                                role="option"
                                aria-disabled="false"
                                aria-selected="false"
                              >
                                <span className="text">EUR</span>
                                <span className="glyphicon glyphicon-ok check-mark" />
                              </a>
                            </li>
                            <li data-original-index={3}>
                              <a
                                tabIndex={0}
                                className=""
                                data-tokens="null"
                                role="option"
                                aria-disabled="false"
                                aria-selected="false"
                              >
                                <span className="text">LTC</span>
                                <span className="glyphicon glyphicon-ok check-mark" />
                              </a>
                            </li>
                            <li data-original-index={4}>
                              <a
                                tabIndex={0}
                                className=""
                                data-tokens="null"
                                role="option"
                                aria-disabled="false"
                                aria-selected="false"
                              >
                                <span className="text">RUB</span>
                                <span className="glyphicon glyphicon-ok check-mark" />
                              </a>
                            </li>
                          </ul>
                        </div>
                        <select
                          className="selectpicker"
                          data-width="80px"
                          tabIndex={-98}
                        >
                          <option>BTC</option>
                          <option>USD</option>
                          <option>EUR</option>
                          <option>LTC</option>
                          <option>RUB</option>
                        </select>
                      </div>
                    </div>
                    <input type="text" className="form-control" />
                  </div>
                  <div className="exchange-btn">
                    <span className="lnr lnr-arrow-right" />
                  </div>
                  <div className="input-group">
                    <div className="input-group-btn">
                      <div
                        className="btn-group bootstrap-select"
                        style={{ width: 80 }}
                      >
                        <button
                          type="button"
                          className="btn dropdown-toggle"
                          data-toggle="dropdown"
                          role="button"
                          title="USD"
                        >
                          <span className="filter-option pull-left">USD</span>&nbsp;
                          <span className="bs-caret">
                            <span className="caret" />
                          </span>
                        </button>
                        <div className="dropdown-menu open" role="combobox">
                          <ul
                            className="dropdown-menu inner"
                            role="listbox"
                            aria-expanded="false"
                            style={{ opacity: 1, display: "none" }}
                          >
                            <li data-original-index={0} className="selected">
                              <a
                                tabIndex={0}
                                className=""
                                data-tokens="null"
                                role="option"
                                aria-disabled="false"
                                aria-selected="true"
                              >
                                <span className="text">USD</span>
                                <span className="glyphicon glyphicon-ok check-mark" />
                              </a>
                            </li>
                            <li data-original-index={1}>
                              <a
                                tabIndex={0}
                                className=""
                                data-tokens="null"
                                role="option"
                                aria-disabled="false"
                                aria-selected="false"
                              >
                                <span className="text">BTC</span>
                                <span className="glyphicon glyphicon-ok check-mark" />
                              </a>
                            </li>
                            <li data-original-index={2}>
                              <a
                                tabIndex={0}
                                className=""
                                data-tokens="null"
                                role="option"
                                aria-disabled="false"
                                aria-selected="false"
                              >
                                <span className="text">EUR</span>
                                <span className="glyphicon glyphicon-ok check-mark" />
                              </a>
                            </li>
                            <li data-original-index={3}>
                              <a
                                tabIndex={0}
                                className=""
                                data-tokens="null"
                                role="option"
                                aria-disabled="false"
                                aria-selected="false"
                              >
                                <span className="text">LTC</span>
                                <span className="glyphicon glyphicon-ok check-mark" />
                              </a>
                            </li>
                            <li data-original-index={4}>
                              <a
                                tabIndex={0}
                                className=""
                                data-tokens="null"
                                role="option"
                                aria-disabled="false"
                                aria-selected="false"
                              >
                                <span className="text">RUB</span>
                                <span className="glyphicon glyphicon-ok check-mark" />
                              </a>
                            </li>
                          </ul>
                        </div>
                        <select
                          className="selectpicker"
                          data-width="80px"
                          tabIndex={-98}
                        >
                          <option>USD</option>
                          <option>BTC</option>
                          <option>EUR</option>
                          <option>LTC</option>
                          <option>RUB</option>
                        </select>
                      </div>
                    </div>
                    <input type="text" className="form-control" />
                  </div>
                </form>
                <div className="exchange-info">
                  <h4>How it works?</h4>
                  <p>
                    It is a long established fact that a reader will be distracted
                    by the readable content of a page when looking at its layout.
                    The point of using Lorem Ipsum is that it has a more-or-less
                    normal distribution of letters, as opposed to using 'Content
                    here, content here', making it look like readable English.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /.End of calculate */}
      <div className="features__content">
        <div id="content__bg">
          <canvas
            className="particles-js-canvas-el"
            width={1349}
            height={721}
            style={{ width: "100%", height: "100%" }}
          />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-sm-8 col-sm-offset-2">
              <div className="section_title">
                <h3>
                  Service we <span>Provide</span>
                </h3>
                <p>
                  It is a long established fact that a reader will be distracted by
                  the readable content of a page when looking at its layout. The
                  point of using Lorem Ipsum.
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-4 col-md-3">
            <div className="feature__box">
              <i className="flaticon-analytics" />
              <div className="feature__content">
                <h3>Data Protection</h3>
                <p>
                  It is a long established fact that a reader will be distracted by
                  the readable content of a page when looking at its layout.
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-4 col-md-3">
            <div className="feature__box">
              <i className="flaticon-secure-shield" />
              <div className="feature__content">
                <h3>Security Protected</h3>
                <p>
                  It is a long established fact that a reader will be distracted by
                  the readable content of a page when looking at its layout.
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-4 col-md-3">
            <div className="feature__box">
              <i className="flaticon-people" />
              <div className="feature__content">
                <h3>Support 24/7</h3>
                <p>
                  It is a long established fact that a reader will be distracted by
                  the readable content of a page when looking at its layout.
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-4 col-md-3">
            <div className="feature__box">
              <i className="flaticon-credit-card" />
              <div className="feature__content">
                <h3>Payment Methods</h3>
                <p>
                  It is a long established fact that a reader will be distracted by
                  the readable content of a page when looking at its layout.
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-4 col-md-3">
            <div className="feature__box">
              <i className="flaticon-money-2" />
              <div className="feature__content">
                <h3>Registered Company</h3>
                <p>
                  It is a long established fact that a reader will be distracted by
                  the readable content of a page when looking at its layout.
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-4 col-md-3">
            <div className="feature__box">
              <i className="flaticon-money-1" />
              <div className="feature__content">
                <h3>Secured Company</h3>
                <p>
                  It is a long established fact that a reader will be distracted by
                  the readable content of a page when looking at its layout.
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-4 col-md-3">
            <div className="feature__box">
              <i className="flaticon-bitcoin" />
              <div className="feature__content">
                <h3>Live Exchange Rates</h3>
                <p>
                  It is a long established fact that a reader will be distracted by
                  the readable content of a page when looking at its layout.
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-4 col-md-3">
            <div className="feature__box">
              <i className="flaticon-bitcoin-1" />
              <div className="feature__content">
                <h3>Cryptocurrency Investments</h3>
                <p>
                  It is a long established fact that a reader will be distracted by
                  the readable content of a page when looking at its layout.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End of features content */}
      <div className="crypto-strat">
        <div className="container">
          <div className="row">
            <div className="col-sm-8 col-sm-offset-2">
              <div className="section_title">
                <h3>
                  How to Get <span>Start</span>
                </h3>
                <p>
                  It is a long established fact that a reader will be distracted by
                  the readable content of a page when looking at its layout. The
                  point of using Lorem Ipsum.
                </p>
              </div>
            </div>
          </div>
          {/*<div class="crypto-strat-title"><span>How to Get Start</span></div>*/}
          <div className="start-steps">
            <div className="start-step">
              {/*<span class="start-step-number">1</span>*/}
              <i className="step-icon flaticon-wallet" />
              <div className="start-step-info">
                <div className="step-name">
                  <span>Choose your Wallet</span>
                </div>
                <div className="step-text">
                  <span>
                    All the Lorem Ipsum generators on the Internet tend to rdefined
                    chunks as making this the
                  </span>
                </div>
              </div>
            </div>
            <div className="start-step">
              {/*<span class="start-step-number">2</span>*/}
              <i className="step-icon flaticon-credit-card" />
              <div className="start-step-info">
                <div className="step-name">
                  <span>Make Payment</span>
                </div>
                <div className="step-text">
                  <span>
                    All the Lorem Ipsum generators on the Internet tend to rdefined
                    chunks as making this the
                  </span>
                </div>
              </div>
            </div>
            <div className="start-step">
              {/*<span class="start-step-number">3</span>*/}
              <i className="step-icon flaticon-money-1" />
              <div className="start-step-info">
                <div className="step-name">
                  <span>Buy or Sell Orders</span>
                </div>
                <div className="step-text">
                  <span>
                    All the Lorem Ipsum generators on the Internet tend to rdefined
                    chunks as making this the
                  </span>
                </div>
              </div>
            </div>
          </div>
          <a href="#" className="btn btn-default">
            Get Start
          </a>
        </div>
      </div>
      {/* /.End of How to Get  Start */}
      
      <div className="currency-table">
        <div className="with-nav-tabs currency-tabs">
          <div className="tab-header">
            <ul className="nav nav-tabs">
              <li className="active">
                <a href="#crypto" data-toggle="tab">
                  Crypto
                </a>
              </li>
              <li>
                <a href="#forex" data-toggle="tab">
                  Forex
                </a>
              </li>
              <li>
                <a href="#stocks" data-toggle="tab">
                  Stocks
                </a>
              </li>
            </ul>
          </div>
          <div className="container">
            <div className="tab-content">
              <div className="tab-pane fade in active" id="crypto">
                <div
                  id="cryptoTable_wrapper"
                  className="dataTables_wrapper form-inline dt-bootstrap no-footer"
                >
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="dataTables_length" id="cryptoTable_length">
                        <label>
                          Show{" "}
                          <select
                            name="cryptoTable_length"
                            aria-controls="cryptoTable"
                            className="form-control input-sm"
                          >
                            <option value={10}>10</option>
                            <option value={25}>25</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                          </select>{" "}
                          entries
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div id="cryptoTable_filter" className="dataTables_filter">
                        <label>
                          Search:
                          <input
                            type="search"
                            className="form-control input-sm"
                            placeholder=""
                            aria-controls="cryptoTable"
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <table
                         id="cryptoTable"
                         class="table table-striped table-hover nowrap dataTable no-footer dtr-inline"
                         role="grid"
                         aria-describedby="cryptoTable_info"
                        style={{ width: '1100px'}}
                      >
                        <thead>
                          <tr role="row">
                            <th
                              className="sorting_asc"
                              tabIndex={0}
                              aria-controls="cryptoTable"
                              rowSpan={1}
                              colSpan={1}
                              style={{ width: 308 }}
                              aria-sort="ascending"
                              aria-label="Name: activate to sort column descending"
                            >
                              Name
                            </th>
                            <th
                              className="sorting"
                              tabIndex={0}
                              aria-controls="cryptoTable"
                              rowSpan={1}
                              colSpan={1}
                              style={{ width: 132 }}
                              aria-label="Ticker: activate to sort column ascending"
                            >
                              Ticker
                            </th>
                            <th
                              className="sorting"
                              tabIndex={0}
                              aria-controls="cryptoTable"
                              rowSpan={1}
                              colSpan={1}
                              style={{ width: 132 }}
                              aria-label="Price: activate to sort column ascending"
                            >
                              Price
                            </th>
                            <th
                              className="sorting"
                              tabIndex={0}
                              aria-controls="cryptoTable"
                              rowSpan={1}
                              colSpan={1}
                              style={{ width: 132 }}
                              aria-label="Capitalization: activate to sort column ascending"
                            >
                              Capitalization
                            </th>
                            <th
                              className="sorting"
                              tabIndex={0}
                              aria-controls="cryptoTable"
                              rowSpan={1}
                              colSpan={1}
                              style={{ width: 132 }}
                              aria-label="Max Quantity: activate to sort column ascending"
                            >
                              Max Quantity
                            </th>
                            <th
                              className="sorting"
                              tabIndex={0}
                              aria-controls="cryptoTable"
                              rowSpan={1}
                              colSpan={1}
                              style={{ width: 132 }}
                              aria-label="1D change: activate to sort column ascending"
                            >
                              1D change
                            </th>
                            <th
                              className="sorting"
                              tabIndex={0}
                              aria-controls="cryptoTable"
                              rowSpan={1}
                              colSpan={1}
                              style={{ width: 132 }}
                              aria-label="Graph: activate to sort column ascending"
                            >
                              Graph
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr data-href="price.html" role="row" className="odd">
                            <td tabIndex={0} className="sorting_1">
                              <div className="logo-name">
                                <div className="item-logo">
                                  <img
                                    src="http://cryptomarkethtml.bdtask.com/assets/img/coin-logo/BTC.svg"
                                    className="img-responsive"
                                    alt=""
                                  />
                                </div>
                                <span className="item_name_value">Bitcoin</span>
                              </div>
                            </td>
                            <td>
                              <span className="value_ticker">BTC</span>
                            </td>
                            <td>
                              <span className="value_price">$8,874.19</span>
                            </td>
                            <td style={{}}>
                              <span className="value_cap">$150.36 B</span>
                            </td>
                            <td style={{}}>
                              <span className="value_max_quantity">21 M</span>
                            </td>
                            <td style={{}}>
                              <span className="value_d1_return percent_positive">
                                +13.08%
                              </span>
                            </td>
                            <td style={{}}>
                              <span className="value_graph">
                                <svg viewBox="0 0 500 100" className="chart">
                                  {" "}
                                  <polyline
                                    fill="none"
                                    stroke="#35a947"
                                    strokeWidth={5}
                                    points=" 00,120 20,60 40,80 60,20 80,80 100,80 120,60 140,100 160,90 180,80 200, 110 220, 10 240, 70 260, 100 280, 100 300, 40 320, 0 340, 100 360, 100 380, 120 400, 60 420, 70 440, 80 "
                                  />{" "}
                                </svg>
                              </span>
                            </td>
                          </tr>
                          <tr data-href="price.html" role="row" className="even">
                            <td tabIndex={0} className="sorting_1">
                              <div className="logo-name">
                                <div className="item-logo">
                                  <img
                                    src="http://cryptomarkethtml.bdtask.com/assets/img/coin-logo/BTG.svg"
                                    className="img-responsive"
                                    alt=""
                                  />
                                </div>
                                <span className="item_name_value">
                                  Bitcoin Gold
                                </span>
                              </div>
                            </td>
                            <td>
                              <span className="value_ticker">BTG</span>
                            </td>
                            <td>
                              <span className="value_price">$108.07</span>
                            </td>
                            <td style={{}}>
                              <span className="value_cap">$381,159</span>
                            </td>
                            <td style={{}}>
                              <span className="value_max_quantity">Unlimited</span>
                            </td>
                            <td style={{}}>
                              <span className="value_d1_return percent_positive">
                                -12.93%
                              </span>
                            </td>
                            <td style={{}}>
                              <span className="value_graph">
                                <svg viewBox="0 0 500 100" className="chart">
                                  {" "}
                                  <polyline
                                    fill="none"
                                    stroke="#35a947"
                                    strokeWidth={5}
                                    points=" 00,120 20,60 40,80 60,20 80,80 100,80 120,60 140,100 160,90 180,80 200, 110 220, 10 240, 70 260, 100 280, 100 300, 40 320, 0 340, 100 360, 100 380, 120 400, 60 420, 70 440, 80 "
                                  />{" "}
                                </svg>
                              </span>
                            </td>
                          </tr>
                          <tr data-href="price.html" role="row" className="odd">
                            <td tabIndex={0} className="sorting_1">
                              <div className="logo-name">
                                <div className="item-logo">
                                  <img
                                    src="http://cryptomarkethtml.bdtask.com/assets/img/coin-logo/DASH.svg"
                                    className="img-responsive"
                                    alt=""
                                  />
                                </div>
                                <span className="item_name_value">Dash</span>
                              </div>
                            </td>
                            <td>
                              <span className="value_ticker">DASH</span>
                            </td>
                            <td>
                              <span className="value_price">$578.69</span>
                            </td>
                            <td style={{}}>
                              <span className="value_cap">$4.55 B</span>
                            </td>
                            <td style={{}}>
                              <span className="value_max_quantity">18.9 M</span>
                            </td>
                            <td style={{}}>
                              <span className="value_d1_return percent_positive">
                                -13.21%
                              </span>
                            </td>
                            <td style={{}}>
                              <span className="value_graph">
                                <svg viewBox="0 0 500 100" className="chart">
                                  {" "}
                                  <polyline
                                    fill="none"
                                    stroke="#35a947"
                                    strokeWidth={5}
                                    points=" 00,120 20,60 40,80 60,20 80,80 100,80 120,60 140,100 160,90 180,80 200, 110 220, 10 240, 70 260, 100 280, 100 300, 40 320, 0 340, 100 360, 100 380, 120 400, 60 420, 70 440, 80 "
                                  />{" "}
                                </svg>
                              </span>
                            </td>
                          </tr>
                          <tr data-href="price.html" role="row" className="even">
                            <td tabIndex={0} className="sorting_1">
                              <div className="logo-name">
                                <div className="item-logo">
                                  <img
                                    src="http://cryptomarkethtml.bdtask.com/assets/img/coin-logo/ETH.svg"
                                    className="img-responsive"
                                    alt=""
                                  />
                                </div>
                                <span className="item_name_value">Ethereum</span>
                              </div>
                            </td>
                            <td>
                              <span className="value_ticker">ETH</span>
                            </td>
                            <td>
                              <span className="value_price">$864.14</span>
                            </td>
                            <td style={{}}>
                              <span className="value_cap">$85.68 B</span>
                            </td>
                            <td style={{}}>
                              <span className="value_max_quantity">Unlimited</span>
                            </td>
                            <td style={{}}>
                              <span className="value_d1_return percent_positive">
                                +6.82%
                              </span>
                            </td>
                            <td style={{}}>
                              <span className="value_graph">
                                <svg viewBox="0 0 500 100" className="chart">
                                  {" "}
                                  <polyline
                                    fill="none"
                                    stroke="#35a947"
                                    strokeWidth={5}
                                    points=" 00,120 20,60 40,80 60,20 80,80 100,80 120,60 140,100 160,90 180,80 200, 110 220, 10 240, 70 260, 100 280, 100 300, 40 320, 0 340, 100 360, 100 380, 120 400, 60 420, 70 440, 80 "
                                  />{" "}
                                </svg>
                              </span>
                            </td>
                          </tr>
                          <tr data-href="price.html" role="row" className="odd">
                            <td tabIndex={0} className="sorting_1">
                              <div className="logo-name">
                                <div className="item-logo">
                                  <img
                                    src="http://cryptomarkethtml.bdtask.com/assets/img/coin-logo/ETC.svg"
                                    className="img-responsive"
                                    alt=""
                                  />
                                </div>
                                <span className="item_name_value">
                                  Ethereum Classic
                                </span>
                              </div>
                            </td>
                            <td>
                              <span className="value_ticker">ETC</span>
                            </td>
                            <td>
                              <span className="value_price">$22.23</span>
                            </td>
                            <td style={{}}>
                              <span className="value_cap">$2.21 B</span>
                            </td>
                            <td style={{}}>
                              <span className="value_max_quantity">Unlimited</span>
                            </td>
                            <td style={{}}>
                              <span className="value_d1_return percent_positive">
                                -17.05%
                              </span>
                            </td>
                            <td style={{}}>
                              <span className="value_graph">
                                <svg viewBox="0 0 500 100" className="chart">
                                  {" "}
                                  <polyline
                                    fill="none"
                                    stroke="#35a947"
                                    strokeWidth={5}
                                    points=" 00,120 20,60 40,80 60,20 80,80 100,80 120,60 140,100 160,90 180,80 200, 110 220, 10 240, 70 260, 100 280, 100 300, 40 320, 0 340, 100 360, 100 380, 120 400, 60 420, 70 440, 80 "
                                  />{" "}
                                </svg>
                              </span>
                            </td>
                          </tr>
                          <tr data-href="price.html" role="row" className="even">
                            <td tabIndex={0} className="sorting_1">
                              <div className="logo-name">
                                <div className="item-logo">
                                  <img
                                    src="http://cryptomarkethtml.bdtask.com/assets/img/coin-logo/LTC.svg"
                                    className="img-responsive"
                                    alt=""
                                  />
                                </div>
                                <span className="item_name_value">Litecoin</span>
                              </div>
                            </td>
                            <td>
                              <span className="value_ticker">LTC</span>
                            </td>
                            <td>
                              <span className="value_price">$145.25</span>
                            </td>
                            <td style={{}}>
                              <span className="value_cap">$8.05 B</span>
                            </td>
                            <td style={{}}>
                              <span className="value_max_quantity">84 M</span>
                            </td>
                            <td style={{}}>
                              <span className="value_d1_return percent_negative">
                                -11.53%
                              </span>
                            </td>
                            <td style={{}}>
                              <span className="value_graph">
                                <svg viewBox="0 0 500 100" className="chart">
                                  {" "}
                                  <polyline
                                    fill="none"
                                    stroke="#e34828"
                                    strokeWidth={5}
                                    points=" 00,120 20,60 40,80 60,20 80,80 100,80 120,60 140,100 160,90 180,80 200, 110 220, 10 240, 70 260, 100 280, 100 300, 40 320, 0 340, 100 360, 100 380, 120 400, 60 420, 70 440, 80 "
                                  />{" "}
                                </svg>
                              </span>
                            </td>
                          </tr>
                          <tr data-href="price.html" role="row" className="odd">
                            <td className="sorting_1" tabIndex={0}>
                              <div className="logo-name">
                                <div className="item-logo">
                                  <img
                                    src="http://cryptomarkethtml.bdtask.com/assets/img/coin-logo/neo.svg"
                                    className="img-responsive"
                                    alt=""
                                  />
                                </div>
                                <span className="item_name_value">Neo</span>
                              </div>
                            </td>
                            <td>
                              <span className="value_ticker">NEO</span>
                            </td>
                            <td>
                              <span className="value_price">$22.23</span>
                            </td>
                            <td style={{}}>
                              <span className="value_cap">$2.21 B</span>
                            </td>
                            <td style={{}}>
                              <span className="value_max_quantity">140.25 M</span>
                            </td>
                            <td style={{}}>
                              <span className="value_d1_return percent_positive">
                                -17.05%
                              </span>
                            </td>
                            <td style={{}}>
                              <span className="value_graph">
                                <svg viewBox="0 0 500 100" className="chart">
                                  {" "}
                                  <polyline
                                    fill="none"
                                    stroke="#35a947"
                                    strokeWidth={5}
                                    points=" 00,120 20,60 40,80 60,20 80,80 100,80 120,60 140,100 160,90 180,80 200, 110 220, 10 240, 70 260, 100 280, 100 300, 40 320, 0 340, 100 360, 100 380, 120 400, 60 420, 70 440, 80 "
                                  />{" "}
                                </svg>
                              </span>
                            </td>
                          </tr>
                          <tr data-href="price.html" role="row" className="even">
                            <td tabIndex={0} className="sorting_1">
                              <div className="logo-name">
                                <div className="item-logo">
                                  <img
                                    src="http://cryptomarkethtml.bdtask.com/assets/img/coin-logo/qtum.svg"
                                    className="img-responsive"
                                    alt=""
                                  />
                                </div>
                                <span className="item_name_value">Qtum</span>
                              </div>
                            </td>
                            <td>
                              <span className="value_ticker">BTG</span>
                            </td>
                            <td>
                              <span className="value_price">$108.07</span>
                            </td>
                            <td style={{}}>
                              <span className="value_cap">$381,159</span>
                            </td>
                            <td style={{}}>
                              <span className="value_max_quantity">Unlimited</span>
                            </td>
                            <td style={{}}>
                              <span className="value_d1_return percent_negative">
                                -12.93%
                              </span>
                            </td>
                            <td style={{}}>
                              <span className="value_graph">
                                <svg viewBox="0 0 500 100" className="chart">
                                  {" "}
                                  <polyline
                                    fill="none"
                                    stroke="#e34828"
                                    strokeWidth={5}
                                    points=" 00,120 20,60 40,80 60,20 80,80 100,80 120,60 140,100 160,90 180,80 200, 110 220, 10 240, 70 260, 100 280, 100 300, 40 320, 0 340, 100 360, 100 380, 120 400, 60 420, 70 440, 80 "
                                  />{" "}
                                </svg>
                              </span>
                            </td>
                          </tr>
                          <tr data-href="price.html" role="row" className="odd">
                            <td tabIndex={0} className="sorting_1">
                              <div className="logo-name">
                                <div className="item-logo">
                                  <img
                                    src="http://cryptomarkethtml.bdtask.com/assets/img/coin-logo/XRP.svg"
                                    className="img-responsive"
                                    alt=""
                                  />
                                </div>
                                <span className="item_name_value">Ripple</span>
                              </div>
                            </td>
                            <td>
                              <span className="value_ticker">XRP</span>
                            </td>
                            <td>
                              <span className="value_price">$1.06</span>
                            </td>
                            <td style={{}}>
                              <span className="value_cap">$43.16 B</span>
                            </td>
                            <td style={{}}>
                              <span className="value_max_quantity">99.99 B</span>
                            </td>
                            <td style={{}}>
                              <span className="value_d1_return percent_positive">
                                +43.04%
                              </span>
                            </td>
                            <td style={{}}>
                              <span className="value_graph">
                                <svg viewBox="0 0 500 100" className="chart">
                                  {" "}
                                  <polyline
                                    fill="none"
                                    stroke="#35a947"
                                    strokeWidth={5}
                                    points=" 00,120 20,60 40,80 60,20 80,80 100,80 120,60 140,100 160,90 180,80 200, 110 220, 10 240, 70 260, 100 280, 100 300, 40 320, 0 340, 100 360, 100 380, 120 400, 60 420, 70 440, 80 "
                                  />{" "}
                                </svg>
                              </span>
                            </td>
                          </tr>
                          <tr data-href="price.html" role="row" className="even">
                            <td tabIndex={0} className="sorting_1">
                              <div className="logo-name">
                                <div className="item-logo">
                                  <img
                                    src="http://cryptomarkethtml.bdtask.com/assets/img/coin-logo/stellar.svg"
                                    className="img-responsive"
                                    alt=""
                                  />
                                </div>
                                <span className="item_name_value">Stellar</span>
                              </div>
                            </td>
                            <td>
                              <span className="value_ticker">XLM</span>
                            </td>
                            <td>
                              <span className="value_price">$0.35</span>
                            </td>
                            <td style={{}}>
                              <span className="value_cap">$6.69 B</span>
                            </td>
                            <td style={{}}>
                              <span className="value_max_quantity">103.27 B</span>
                            </td>
                            <td style={{}}>
                              <span className="value_d1_return percent_positive">
                                -13.7%
                              </span>
                            </td>
                            <td style={{}}>
                              <span className="value_graph">
                                <svg viewBox="0 0 500 100" className="chart">
                                  {" "}
                                  <polyline
                                    fill="none"
                                    stroke="#35a947"
                                    strokeWidth={5}
                                    points=" 00,120 20,60 40,80 60,20 80,80 100,80 120,60 140,100 160,90 180,80 200, 110 220, 10 240, 70 260, 100 280, 100 300, 40 320, 0 340, 100 360, 100 380, 120 400, 60 420, 70 440, 80 "
                                  />{" "}
                                </svg>
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-5">
                      <div
                        className="dataTables_info"
                        id="cryptoTable_info"
                        role="status"
                        aria-live="polite"
                      >
                        Showing 1 to 10 of 12 entries
                      </div>
                    </div>
                    <div className="col-sm-7">
                      <div
                        className="dataTables_paginate paging_simple_numbers"
                        id="cryptoTable_paginate"
                      >
                        <ul className="pagination">
                          <li
                            className="paginate_button previous disabled"
                            id="cryptoTable_previous"
                          >
                            <a
                              href="#"
                              aria-controls="cryptoTable"
                              data-dt-idx={0}
                              tabIndex={0}
                            >
                              Previous
                            </a>
                          </li>
                          <li className="paginate_button active">
                            <a
                              href="#"
                              aria-controls="cryptoTable"
                              data-dt-idx={1}
                              tabIndex={0}
                            >
                              1
                            </a>
                          </li>
                          <li className="paginate_button ">
                            <a
                              href="#"
                              aria-controls="cryptoTable"
                              data-dt-idx={2}
                              tabIndex={0}
                            >
                              2
                            </a>
                          </li>
                          <li
                            className="paginate_button next"
                            id="cryptoTable_next"
                          >
                            <a
                              href="#"
                              aria-controls="cryptoTable"
                              data-dt-idx={3}
                              tabIndex={0}
                            >
                              Next
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="forex">
                <div
                  id="forexTable_wrapper"
                  className="dataTables_wrapper form-inline dt-bootstrap no-footer"
                >
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="dataTables_length" id="forexTable_length">
                        <label>
                          Show{" "}
                          <select
                            name="forexTable_length"
                            aria-controls="forexTable"
                            className="form-control input-sm"
                          >
                            <option value={10}>10</option>
                            <option value={25}>25</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                          </select>{" "}
                          entries
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div id="forexTable_filter" className="dataTables_filter">
                        <label>
                          Search:
                          <input
                            type="search"
                            className="form-control input-sm"
                            placeholder=""
                            aria-controls="forexTable"
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <table
                        id="forexTable"
                        className="table table-striped table-hover nowrap dataTable no-footer dtr-inline"
                        role="grid"
                        aria-describedby="forexTable_info"
                        style={{ width: 0 }}
                      >
                        <thead>
                          <tr role="row">
                            <th
                              className="sorting_asc"
                              tabIndex={0}
                              aria-controls="forexTable"
                              rowSpan={1}
                              colSpan={1}
                              style={{ width: 0 }}
                              aria-sort="ascending"
                              aria-label="Name: activate to sort column descending"
                            >
                              Name
                            </th>
                            <th
                              className="sorting"
                              tabIndex={0}
                              aria-controls="forexTable"
                              rowSpan={1}
                              colSpan={1}
                              style={{ width: 0 }}
                              aria-label="Ticker: activate to sort column ascending"
                            >
                              Ticker
                            </th>
                            <th
                              className="sorting"
                              tabIndex={0}
                              aria-controls="forexTable"
                              rowSpan={1}
                              colSpan={1}
                              style={{ width: 0 }}
                              aria-label="Bid: activate to sort column ascending"
                            >
                              Bid
                            </th>
                            <th
                              className="sorting"
                              tabIndex={0}
                              aria-controls="forexTable"
                              rowSpan={1}
                              colSpan={1}
                              style={{ width: 0 }}
                              aria-label="Ask: activate to sort column ascending"
                            >
                              Ask
                            </th>
                            <th
                              className="sorting"
                              tabIndex={0}
                              aria-controls="forexTable"
                              rowSpan={1}
                              colSpan={1}
                              style={{ width: 0 }}
                              aria-label="Spread: activate to sort column ascending"
                            >
                              Spread
                            </th>
                            <th
                              className="sorting"
                              tabIndex={0}
                              aria-controls="forexTable"
                              rowSpan={1}
                              colSpan={1}
                              style={{ width: 0 }}
                              aria-label="1D change: activate to sort column ascending"
                            >
                              1D change
                            </th>
                            <th
                              className="sorting"
                              tabIndex={0}
                              aria-controls="forexTable"
                              rowSpan={1}
                              colSpan={1}
                              style={{ width: 0 }}
                              aria-label="Graph: activate to sort column ascending"
                            >
                              Graph
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr data-href="price.html" role="row" className="odd">
                            <td tabIndex={0} className="sorting_1">
                              <div className="logo-name">
                                <div className="item-logo">
                                  <img
                                    src="http://cryptomarkethtml.bdtask.com/assets/img/coin-logo/EURGBP.svg"
                                    className="img-responsive"
                                    alt=""
                                  />
                                </div>
                                <span className="item_name_value">EUR/GBP</span>
                              </div>
                            </td>
                            <td>
                              <span className="value_ticker">EURGBP</span>
                            </td>
                            <td>
                              <span className="value_price">0.88621</span>
                            </td>
                            <td>
                              <span className="value_cap">0.88649</span>
                            </td>
                            <td>
                              <span className="value_spread">0.032%</span>
                            </td>
                            <td>
                              <span className="value_d1_return percent_positive">
                                +0.77%
                              </span>
                            </td>
                            <td>
                              <span className="value_graph">
                                <svg viewBox="0 0 500 100" className="chart">
                                  {" "}
                                  <polyline
                                    fill="none"
                                    stroke="#35a947"
                                    strokeWidth={5}
                                    points=" 00,120 20,60 40,80 60,20 80,80 100,80 120,60 140,100 160,90 180,80 200, 110 220, 10 240, 70 260, 100 280, 100 300, 40 320, 0 340, 100 360, 100 380, 120 400, 60 420, 70 440, 80 "
                                  />{" "}
                                </svg>
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-5">
                      <div
                        className="dataTables_info"
                        id="forexTable_info"
                        role="status"
                        aria-live="polite"
                      >
                        Showing 1 to 1 of 1 entries
                      </div>
                    </div>
                    <div className="col-sm-7">
                      <div
                        className="dataTables_paginate paging_simple_numbers"
                        id="forexTable_paginate"
                      >
                        <ul className="pagination">
                          <li
                            className="paginate_button previous disabled"
                            id="forexTable_previous"
                          >
                            <a
                              href="#"
                              aria-controls="forexTable"
                              data-dt-idx={0}
                              tabIndex={0}
                            >
                              Previous
                            </a>
                          </li>
                          <li className="paginate_button active">
                            <a
                              href="#"
                              aria-controls="forexTable"
                              data-dt-idx={1}
                              tabIndex={0}
                            >
                              1
                            </a>
                          </li>
                          <li
                            className="paginate_button next disabled"
                            id="forexTable_next"
                          >
                            <a
                              href="#"
                              aria-controls="forexTable"
                              data-dt-idx={2}
                              tabIndex={0}
                            >
                              Next
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="stocks">
                <div
                  id="stocksTable_wrapper"
                  className="dataTables_wrapper form-inline dt-bootstrap no-footer"
                >
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="dataTables_length" id="stocksTable_length">
                        <label>
                          Show{" "}
                          <select
                            name="stocksTable_length"
                            aria-controls="stocksTable"
                            className="form-control input-sm"
                          >
                            <option value={10}>10</option>
                            <option value={25}>25</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                          </select>{" "}
                          entries
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div id="stocksTable_filter" className="dataTables_filter">
                        <label>
                          Search:
                          <input
                            type="search"
                            className="form-control input-sm"
                            placeholder=""
                            aria-controls="stocksTable"
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <table
                        id="stocksTable"
                        className="table table-striped table-hover nowrap dataTable no-footer dtr-inline"
                        role="grid"
                        aria-describedby="stocksTable_info"
                        style={{ width: 0 }}
                      >
                        <thead>
                          <tr role="row">
                            <th
                              className="sorting_asc"
                              tabIndex={0}
                              aria-controls="stocksTable"
                              rowSpan={1}
                              colSpan={1}
                              style={{ width: 0 }}
                              aria-sort="ascending"
                              aria-label="Name: activate to sort column descending"
                            >
                              Name
                            </th>
                            <th
                              className="sorting"
                              tabIndex={0}
                              aria-controls="stocksTable"
                              rowSpan={1}
                              colSpan={1}
                              style={{ width: 0 }}
                              aria-label="Ticker: activate to sort column ascending"
                            >
                              Ticker
                            </th>
                            <th
                              className="sorting"
                              tabIndex={0}
                              aria-controls="stocksTable"
                              rowSpan={1}
                              colSpan={1}
                              style={{ width: 0 }}
                              aria-label="Price: activate to sort column ascending"
                            >
                              Price
                            </th>
                            <th
                              className="sorting"
                              tabIndex={0}
                              aria-controls="stocksTable"
                              rowSpan={1}
                              colSpan={1}
                              style={{ width: 0 }}
                              aria-label="Capitalization: activate to sort column ascending"
                            >
                              Capitalization
                            </th>
                            <th
                              className="sorting"
                              tabIndex={0}
                              aria-controls="stocksTable"
                              rowSpan={1}
                              colSpan={1}
                              style={{ width: 0 }}
                              aria-label="Industry: activate to sort column ascending"
                            >
                              Industry
                            </th>
                            <th
                              className="sorting"
                              tabIndex={0}
                              aria-controls="stocksTable"
                              rowSpan={1}
                              colSpan={1}
                              style={{ width: 0 }}
                              aria-label="1D change: activate to sort column ascending"
                            >
                              1D change
                            </th>
                            <th
                              className="sorting"
                              tabIndex={0}
                              aria-controls="stocksTable"
                              rowSpan={1}
                              colSpan={1}
                              style={{ width: 0 }}
                              aria-label="Graph: activate to sort column ascending"
                            >
                              Graph
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr data-href="price.html" role="row" className="odd">
                            <td tabIndex={0} className="sorting_1">
                              <div className="logo-name">
                                <div className="item-logo">
                                  <img
                                    src="http://cryptomarkethtml.bdtask.com/assets/img/coin-logo/GOOG.svg"
                                    className="img-responsive"
                                    alt=""
                                  />
                                </div>
                                <span className="item_name_value">
                                  Alphabet Inc.
                                </span>
                              </div>
                            </td>
                            <td>
                              <span className="value_ticker">GOOGL</span>
                            </td>
                            <td>
                              <span className="value_price">$864.14</span>
                            </td>
                            <td>
                              <span className="value_cap">$85.68 B</span>
                            </td>
                            <td>
                              <span className="value_overflow">
                                Information Technology
                              </span>
                            </td>
                            <td>
                              <span className="value_d1_return percent_positive">
                                +6.82%
                              </span>
                            </td>
                            <td>
                              <span className="value_graph">
                                <svg viewBox="0 0 500 100" className="chart">
                                  {" "}
                                  <polyline
                                    fill="none"
                                    stroke="#35a947"
                                    strokeWidth={5}
                                    points=" 00,120 20,60 40,80 60,20 80,80 100,80 120,60 140,100 160,90 180,80 200, 110 220, 10 240, 70 260, 100 280, 100 300, 40 320, 0 340, 100 360, 100 380, 120 400, 60 420, 70 440, 80 "
                                  />{" "}
                                </svg>
                              </span>
                            </td>
                          </tr>
                          <tr data-href="price.html" role="row" className="even">
                            <td tabIndex={0} className="sorting_1">
                              <div className="logo-name">
                                <div className="item-logo">
                                  <img
                                    src="http://cryptomarkethtml.bdtask.com/assets/img/coin-logo/AMZN.svg"
                                    className="img-responsive"
                                    alt=""
                                  />
                                </div>
                                <span className="item_name_value">Amazon</span>
                              </div>
                            </td>
                            <td>
                              <span className="value_ticker">AMZN</span>
                            </td>
                            <td>
                              <span className="value_price">$1.06</span>
                            </td>
                            <td>
                              <span className="value_cap">$43.16 B</span>
                            </td>
                            <td>
                              <span className="value_overflow">
                                Consumer Discretionary
                              </span>
                            </td>
                            <td>
                              <span className="value_d1_return percent_positive">
                                +43.04%
                              </span>
                            </td>
                            <td>
                              <span className="value_graph">
                                <svg viewBox="0 0 500 100" className="chart">
                                  {" "}
                                  <polyline
                                    fill="none"
                                    stroke="#35a947"
                                    strokeWidth={5}
                                    points=" 00,120 20,60 40,80 60,20 80,80 100,80 120,60 140,100 160,90 180,80 200, 110 220, 10 240, 70 260, 100 280, 100 300, 40 320, 0 340, 100 360, 100 380, 120 400, 60 420, 70 440, 80 "
                                  />{" "}
                                </svg>
                              </span>
                            </td>
                          </tr>
                          <tr data-href="price.html" role="row" className="odd">
                            <td tabIndex={0} className="sorting_1">
                              <div className="logo-name">
                                <div className="item-logo">
                                  <img
                                    src="http://cryptomarkethtml.bdtask.com/assets/img/coin-logo/AAPL.svg"
                                    className="img-responsive"
                                    alt=""
                                  />
                                </div>
                                <span className="item_name_value">Apple Inc.</span>
                              </div>
                            </td>
                            <td>
                              <span className="value_ticker">AAPL</span>
                            </td>
                            <td>
                              <span className="value_price">$8,874.19</span>
                            </td>
                            <td>
                              <span className="value_cap">$150.36 B</span>
                            </td>
                            <td>
                              <span className="value_overflow">
                                Information Technology
                              </span>
                            </td>
                            <td>
                              <span className="value_d1_return percent_positive">
                                +13.08%
                              </span>
                            </td>
                            <td>
                              <span className="value_graph">
                                <svg viewBox="0 0 500 100" className="chart">
                                  {" "}
                                  <polyline
                                    fill="none"
                                    stroke="#35a947"
                                    strokeWidth={5}
                                    points=" 00,120 20,60 40,80 60,20 80,80 100,80 120,60 140,100 160,90 180,80 200, 110 220, 10 240, 70 260, 100 280, 100 300, 40 320, 0 340, 100 360, 100 380, 120 400, 60 420, 70 440, 80 "
                                  />{" "}
                                </svg>
                              </span>
                            </td>
                          </tr>
                          <tr data-href="price.html" role="row" className="even">
                            <td tabIndex={0} className="sorting_1">
                              <div className="logo-name">
                                <div className="item-logo">
                                  <img
                                    src="http://cryptomarkethtml.bdtask.com/assets/img/coin-logo/BTG.svg"
                                    className="img-responsive"
                                    alt=""
                                  />
                                </div>
                                <span className="item_name_value">
                                  Bitcoin Gold
                                </span>
                              </div>
                            </td>
                            <td>
                              <span className="value_ticker">BTG</span>
                            </td>
                            <td>
                              <span className="value_price">$108.07</span>
                            </td>
                            <td>
                              <span className="value_cap">$381,159</span>
                            </td>
                            <td>
                              <span className="value_max_quantity">Unlimited</span>
                            </td>
                            <td>
                              <span className="value_d1_return percent_positive">
                                -12.93%
                              </span>
                            </td>
                            <td>
                              <span className="value_graph">
                                <svg viewBox="0 0 500 100" className="chart">
                                  {" "}
                                  <polyline
                                    fill="none"
                                    stroke="#35a947"
                                    strokeWidth={5}
                                    points=" 00,120 20,60 40,80 60,20 80,80 100,80 120,60 140,100 160,90 180,80 200, 110 220, 10 240, 70 260, 100 280, 100 300, 40 320, 0 340, 100 360, 100 380, 120 400, 60 420, 70 440, 80 "
                                  />{" "}
                                </svg>
                              </span>
                            </td>
                          </tr>
                          <tr data-href="price.html" role="row" className="odd">
                            <td tabIndex={0} className="sorting_1">
                              <div className="logo-name">
                                <div className="item-logo">
                                  <img
                                    src="http://cryptomarkethtml.bdtask.com/assets/img/coin-logo/DASH.svg"
                                    className="img-responsive"
                                    alt=""
                                  />
                                </div>
                                <span className="item_name_value">Dash</span>
                              </div>
                            </td>
                            <td>
                              <span className="value_ticker">DASH</span>
                            </td>
                            <td>
                              <span className="value_price">$578.69</span>
                            </td>
                            <td>
                              <span className="value_cap">$4.55 B</span>
                            </td>
                            <td>
                              <span className="value_max_quantity">18.9 M</span>
                            </td>
                            <td>
                              <span className="value_d1_return percent_positive">
                                -13.21%
                              </span>
                            </td>
                            <td>
                              <span className="value_graph">
                                <svg viewBox="0 0 500 100" className="chart">
                                  {" "}
                                  <polyline
                                    fill="none"
                                    stroke="#35a947"
                                    strokeWidth={5}
                                    points=" 00,120 20,60 40,80 60,20 80,80 100,80 120,60 140,100 160,90 180,80 200, 110 220, 10 240, 70 260, 100 280, 100 300, 40 320, 0 340, 100 360, 100 380, 120 400, 60 420, 70 440, 80 "
                                  />{" "}
                                </svg>
                              </span>
                            </td>
                          </tr>
                          <tr data-href="price.html" role="row" className="even">
                            <td tabIndex={0} className="sorting_1">
                              <div className="logo-name">
                                <div className="item-logo">
                                  <img
                                    src="http://cryptomarkethtml.bdtask.com/assets/img/coin-logo/ETC.svg"
                                    className="img-responsive"
                                    alt=""
                                  />
                                </div>
                                <span className="item_name_value">
                                  Ethereum Classic
                                </span>
                              </div>
                            </td>
                            <td>
                              <span className="value_ticker">ETC</span>
                            </td>
                            <td>
                              <span className="value_price">$22.23</span>
                            </td>
                            <td>
                              <span className="value_cap">$2.21 B</span>
                            </td>
                            <td>
                              <span className="value_max_quantity">Unlimited</span>
                            </td>
                            <td>
                              <span className="value_d1_return percent_positive">
                                -17.05%
                              </span>
                            </td>
                            <td>
                              <span className="value_graph">
                                <svg viewBox="0 0 500 100" className="chart">
                                  {" "}
                                  <polyline
                                    fill="none"
                                    stroke="#35a947"
                                    strokeWidth={5}
                                    points=" 00,120 20,60 40,80 60,20 80,80 100,80 120,60 140,100 160,90 180,80 200, 110 220, 10 240, 70 260, 100 280, 100 300, 40 320, 0 340, 100 360, 100 380, 120 400, 60 420, 70 440, 80 "
                                  />{" "}
                                </svg>
                              </span>
                            </td>
                          </tr>
                          <tr data-href="price.html" role="row" className="odd">
                            <td tabIndex={0} className="sorting_1">
                              <div className="logo-name">
                                <div className="item-logo">
                                  <img
                                    src="http://cryptomarkethtml.bdtask.com/assets/img/coin-logo/FB.svg"
                                    className="img-responsive"
                                    alt=""
                                  />
                                </div>
                                <span className="item_name_value">Facebook</span>
                              </div>
                            </td>
                            <td>
                              <span className="value_ticker">FB</span>
                            </td>
                            <td>
                              <span className="value_price">$145.25</span>
                            </td>
                            <td>
                              <span className="value_cap">$8.05 B</span>
                            </td>
                            <td>
                              <span className="value_overflow">
                                Information Technology
                              </span>
                            </td>
                            <td>
                              <span className="value_d1_return percent_negative">
                                -11.53%
                              </span>
                            </td>
                            <td>
                              <span className="value_graph">
                                <svg viewBox="0 0 500 100" className="chart">
                                  {" "}
                                  <polyline
                                    fill="none"
                                    stroke="#e34828"
                                    strokeWidth={5}
                                    points=" 00,120 20,60 40,80 60,20 80,80 100,80 120,60 140,100 160,90 180,80 200, 110 220, 10 240, 70 260, 100 280, 100 300, 40 320, 0 340, 100 360, 100 380, 120 400, 60 420, 70 440, 80 "
                                  />{" "}
                                </svg>
                              </span>
                            </td>
                          </tr>
                          <tr data-href="price.html" role="row" className="even">
                            <td tabIndex={0} className="sorting_1">
                              <div className="logo-name">
                                <div className="item-logo">
                                  <img
                                    src="http://cryptomarkethtml.bdtask.com/assets/img/coin-logo/MSFT.svg"
                                    className="img-responsive"
                                    alt=""
                                  />
                                </div>
                                <span className="item_name_value">
                                  Microsoft Corporation
                                </span>
                              </div>
                            </td>
                            <td>
                              <span className="value_ticker">MSFT</span>
                            </td>
                            <td>
                              <span className="value_price">$0.04</span>
                            </td>
                            <td>
                              <span className="value_cap">$2.73 B</span>
                            </td>
                            <td>
                              <span className="value_overflow">
                                Information Technology
                              </span>
                            </td>
                            <td>
                              <span className="value_d1_return percent_negative">
                                -18.58%
                              </span>
                            </td>
                            <td>
                              <span className="value_graph">
                                <svg viewBox="0 0 500 100" className="chart">
                                  {" "}
                                  <polyline
                                    fill="none"
                                    stroke="#e34828"
                                    strokeWidth={5}
                                    points=" 00,120 20,60 40,80 60,20 80,80 100,80 120,60 140,100 160,90 180,80 200, 110 220, 10 240, 70 260, 100 280, 100 300, 40 320, 0 340, 100 360, 100 380, 120 400, 60 420, 70 440, 80 "
                                  />{" "}
                                </svg>
                              </span>
                            </td>
                          </tr>
                          <tr data-href="price.html" role="row" className="odd">
                            <td className="sorting_1" tabIndex={0}>
                              <div className="logo-name">
                                <div className="item-logo">
                                  <img
                                    src="http://cryptomarkethtml.bdtask.com/assets/img/coin-logo/neo.svg"
                                    className="img-responsive"
                                    alt=""
                                  />
                                </div>
                                <span className="item_name_value">Neo</span>
                              </div>
                            </td>
                            <td>
                              <span className="value_ticker">NEO</span>
                            </td>
                            <td>
                              <span className="value_price">$22.23</span>
                            </td>
                            <td>
                              <span className="value_cap">$2.21 B</span>
                            </td>
                            <td>
                              <span className="value_max_quantity">140.25 M</span>
                            </td>
                            <td>
                              <span className="value_d1_return percent_positive">
                                -17.05%
                              </span>
                            </td>
                            <td>
                              <span className="value_graph">
                                <svg viewBox="0 0 500 100" className="chart">
                                  {" "}
                                  <polyline
                                    fill="none"
                                    stroke="#35a947"
                                    strokeWidth={5}
                                    points=" 00,120 20,60 40,80 60,20 80,80 100,80 120,60 140,100 160,90 180,80 200, 110 220, 10 240, 70 260, 100 280, 100 300, 40 320, 0 340, 100 360, 100 380, 120 400, 60 420, 70 440, 80 "
                                  />{" "}
                                </svg>
                              </span>
                            </td>
                          </tr>
                          <tr data-href="price.html" role="row" className="even">
                            <td tabIndex={0} className="sorting_1">
                              <div className="logo-name">
                                <div className="item-logo">
                                  <img
                                    src="http://cryptomarkethtml.bdtask.com/assets/img/coin-logo/qtum.svg"
                                    className="img-responsive"
                                    alt=""
                                  />
                                </div>
                                <span className="item_name_value">Qtum</span>
                              </div>
                            </td>
                            <td>
                              <span className="value_ticker">BTG</span>
                            </td>
                            <td>
                              <span className="value_price">$108.07</span>
                            </td>
                            <td>
                              <span className="value_cap">$381,159</span>
                            </td>
                            <td>
                              <span className="value_max_quantity">Unlimited</span>
                            </td>
                            <td>
                              <span className="value_d1_return percent_negative">
                                -12.93%
                              </span>
                            </td>
                            <td>
                              <span className="value_graph">
                                <svg viewBox="0 0 500 100" className="chart">
                                  {" "}
                                  <polyline
                                    fill="none"
                                    stroke="#e34828"
                                    strokeWidth={5}
                                    points=" 00,120 20,60 40,80 60,20 80,80 100,80 120,60 140,100 160,90 180,80 200, 110 220, 10 240, 70 260, 100 280, 100 300, 40 320, 0 340, 100 360, 100 380, 120 400, 60 420, 70 440, 80 "
                                  />{" "}
                                </svg>
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-5">
                      <div
                        className="dataTables_info"
                        id="stocksTable_info"
                        role="status"
                        aria-live="polite"
                      >
                        Showing 1 to 10 of 12 entries
                      </div>
                    </div>
                    <div className="col-sm-7">
                      <div
                        className="dataTables_paginate paging_simple_numbers"
                        id="stocksTable_paginate"
                      >
                        <ul className="pagination">
                          <li
                            className="paginate_button previous disabled"
                            id="stocksTable_previous"
                          >
                            <a
                              href="#"
                              aria-controls="stocksTable"
                              data-dt-idx={0}
                              tabIndex={0}
                            >
                              Previous
                            </a>
                          </li>
                          <li className="paginate_button active">
                            <a
                              href="#"
                              aria-controls="stocksTable"
                              data-dt-idx={1}
                              tabIndex={0}
                            >
                              1
                            </a>
                          </li>
                          <li className="paginate_button ">
                            <a
                              href="#"
                              aria-controls="stocksTable"
                              data-dt-idx={2}
                              tabIndex={0}
                            >
                              2
                            </a>
                          </li>
                          <li
                            className="paginate_button next"
                            id="stocksTable_next"
                          >
                            <a
                              href="#"
                              aria-controls="stocksTable"
                              data-dt-idx={3}
                              tabIndex={0}
                            >
                              Next
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /.End of currency table */}
      <div className="testimonial-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-sm-8 col-sm-offset-2">
              <div className="section_title">
                <h3>
                  What you should know about <span>cryptocurrencies</span>
                </h3>
                <p>
                  It is a long established fact that a reader will be distracted by
                  the readable content of a page when looking at its layout. The
                  point of using Lorem Ipsum.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="owl-testimonial owl-carousel owl-theme owl-loaded owl-drag">
                <div className="owl-stage-outer">
                  <div
                    className="owl-stage"
                    style={{
                      transform: "translate3d(-1140px, 0px, 0px)",
                      transition: "all 0s ease 0s",
                      width: 3800
                    }}
                  >
                    <div className="owl-item cloned" style={{ width: 380 }}>
                      <div className="testimonial-panel">
                        <div className="tes-quoteInfo">
                          <img
                            src="http://cryptomarkethtml.bdtask.com/assets/img/avatar-1.jpg"
                            className="quoteAvatar"
                            alt=""
                          />
                          <div>
                            <div className="quoteAuthor">
                              <span>Tuhin Sorker</span>
                            </div>
                            <div className="quotePosition">
                              <span>
                                Co-founder of Bdtask, American business magnate
                              </span>
                            </div>
                            <div className="testimonial--rating">
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                            </div>
                          </div>
                        </div>
                        {/* /.testimonial-rating end */}
                        <div className="testimonial--body">
                          <p>
                            “ There are many variations of passages of Lorem Ipsum
                            available, but the majority have suffered alteration in
                            some form, by injected humour. ”
                          </p>
                        </div>
                        {/* /.testimonial-body end */}
                      </div>
                    </div>
                    <div className="owl-item cloned" style={{ width: 380 }}>
                      <div className="testimonial-panel">
                        <div className="tes-quoteInfo">
                          <img
                            src="http://cryptomarkethtml.bdtask.com/assets/img/avatar-1.jpg"
                            className="quoteAvatar"
                            alt=""
                          />
                          <div>
                            <div className="quoteAuthor">
                              <span>Jahangir Alom</span>
                            </div>
                            <div className="quotePosition">
                              <span>
                                Co-founder of Bdtask, American business magnate
                              </span>
                            </div>
                            <div className="testimonial--rating">
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                            </div>
                          </div>
                        </div>
                        {/* /.testimonial-rating end */}
                        <div className="testimonial--body">
                          <p>
                            “ If you are going to use a passage of Lorem Ipsum, you
                            need to be sure there isn't anything embarrassing hidden
                            in the middle of text. ”
                          </p>
                        </div>
                        {/* /.testimonial-body end */}
                      </div>
                    </div>
                    <div className="owl-item cloned" style={{ width: 380 }}>
                      <div className="testimonial-panel">
                        <div className="tes-quoteInfo">
                          <img
                            src="http://cryptomarkethtml.bdtask.com/assets/img/avatar-1.jpg"
                            className="quoteAvatar"
                            alt=""
                          />
                          <div>
                            <div className="quoteAuthor">
                              <span>H.M Isahaq</span>
                            </div>
                            <div className="quotePosition">
                              <span>
                                Co-founder of Bdtask, American business magnate
                              </span>
                            </div>
                            <div className="testimonial--rating">
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                            </div>
                          </div>
                        </div>
                        {/* /.testimonial-rating end */}
                        <div className="testimonial--body">
                          <p>
                            “ Contrary to popular belief, Lorem Ipsum is not simply
                            random text. It has roots in a piece of classical Latin
                            literature from 45 BC, making. ”
                          </p>
                        </div>
                        {/* /.testimonial-body end */}
                      </div>
                    </div>
                    <div className="owl-item active" style={{ width: 380 }}>
                      <div className="testimonial-panel">
                        <div className="tes-quoteInfo">
                          <img
                            src="http://cryptomarkethtml.bdtask.com/assets/img/avatar-1.jpg"
                            className="quoteAvatar"
                            alt=""
                          />
                          <div>
                            <div className="quoteAuthor">
                              <span>Tanjil Ahmed</span>
                            </div>
                            <div className="quotePosition">
                              <span>
                                Co-founder of Bdtask, American business magnate
                              </span>
                            </div>
                            <div className="testimonial--rating">
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                            </div>
                          </div>
                        </div>
                        {/* /.testimonial-rating end */}
                        <div className="testimonial--body">
                          <p>
                            “ It is a long established fact that a reader will be
                            distracted by the readable content of a page when
                            looking at its layout. ”
                          </p>
                        </div>
                        {/* /.testimonial-body end */}
                      </div>
                    </div>
                    <div className="owl-item active" style={{ width: 380 }}>
                      <div className="testimonial-panel">
                        <div className="tes-quoteInfo">
                          <img
                            src="http://cryptomarkethtml.bdtask.com/assets/img/avatar-1.jpg"
                            className="quoteAvatar"
                            alt=""
                          />
                          <div>
                            <div className="quoteAuthor">
                              <span>Tuhin Sorker</span>
                            </div>
                            <div className="quotePosition">
                              <span>
                                Co-founder of Bdtask, American business magnate
                              </span>
                            </div>
                            <div className="testimonial--rating">
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                            </div>
                          </div>
                        </div>
                        {/* /.testimonial-rating end */}
                        <div className="testimonial--body">
                          <p>
                            “ There are many variations of passages of Lorem Ipsum
                            available, but the majority have suffered alteration in
                            some form, by injected humour. ”
                          </p>
                        </div>
                        {/* /.testimonial-body end */}
                      </div>
                    </div>
                    <div className="owl-item active" style={{ width: 380 }}>
                      <div className="testimonial-panel">
                        <div className="tes-quoteInfo">
                          <img
                            src="http://cryptomarkethtml.bdtask.com/assets/img/avatar-1.jpg"
                            className="quoteAvatar"
                            alt=""
                          />
                          <div>
                            <div className="quoteAuthor">
                              <span>Jahangir Alom</span>
                            </div>
                            <div className="quotePosition">
                              <span>
                                Co-founder of Bdtask, American business magnate
                              </span>
                            </div>
                            <div className="testimonial--rating">
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                            </div>
                          </div>
                        </div>
                        {/* /.testimonial-rating end */}
                        <div className="testimonial--body">
                          <p>
                            “ If you are going to use a passage of Lorem Ipsum, you
                            need to be sure there isn't anything embarrassing hidden
                            in the middle of text. ”
                          </p>
                        </div>
                        {/* /.testimonial-body end */}
                      </div>
                    </div>
                    <div className="owl-item" style={{ width: 380 }}>
                      <div className="testimonial-panel">
                        <div className="tes-quoteInfo">
                          <img
                            src="http://cryptomarkethtml.bdtask.com/assets/img/avatar-1.jpg"
                            className="quoteAvatar"
                            alt=""
                          />
                          <div>
                            <div className="quoteAuthor">
                              <span>H.M Isahaq</span>
                            </div>
                            <div className="quotePosition">
                              <span>
                                Co-founder of Bdtask, American business magnate
                              </span>
                            </div>
                            <div className="testimonial--rating">
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                            </div>
                          </div>
                        </div>
                        {/* /.testimonial-rating end */}
                        <div className="testimonial--body">
                          <p>
                            “ Contrary to popular belief, Lorem Ipsum is not simply
                            random text. It has roots in a piece of classical Latin
                            literature from 45 BC, making. ”
                          </p>
                        </div>
                        {/* /.testimonial-body end */}
                      </div>
                    </div>
                    <div className="owl-item cloned" style={{ width: 380 }}>
                      <div className="testimonial-panel">
                        <div className="tes-quoteInfo">
                          <img
                            src="http://cryptomarkethtml.bdtask.com/assets/img/avatar-1.jpg"
                            className="quoteAvatar"
                            alt=""
                          />
                          <div>
                            <div className="quoteAuthor">
                              <span>Tanjil Ahmed</span>
                            </div>
                            <div className="quotePosition">
                              <span>
                                Co-founder of Bdtask, American business magnate
                              </span>
                            </div>
                            <div className="testimonial--rating">
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                            </div>
                          </div>
                        </div>
                        {/* /.testimonial-rating end */}
                        <div className="testimonial--body">
                          <p>
                            “ It is a long established fact that a reader will be
                            distracted by the readable content of a page when
                            looking at its layout. ”
                          </p>
                        </div>
                        {/* /.testimonial-body end */}
                      </div>
                    </div>
                    <div className="owl-item cloned" style={{ width: 380 }}>
                      <div className="testimonial-panel">
                        <div className="tes-quoteInfo">
                          <img
                            src="http://cryptomarkethtml.bdtask.com/assets/img/avatar-1.jpg"
                            className="quoteAvatar"
                            alt=""
                          />
                          <div>
                            <div className="quoteAuthor">
                              <span>Tuhin Sorker</span>
                            </div>
                            <div className="quotePosition">
                              <span>
                                Co-founder of Bdtask, American business magnate
                              </span>
                            </div>
                            <div className="testimonial--rating">
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                            </div>
                          </div>
                        </div>
                        {/* /.testimonial-rating end */}
                        <div className="testimonial--body">
                          <p>
                            “ There are many variations of passages of Lorem Ipsum
                            available, but the majority have suffered alteration in
                            some form, by injected humour. ”
                          </p>
                        </div>
                        {/* /.testimonial-body end */}
                      </div>
                    </div>
                    <div className="owl-item cloned" style={{ width: 380 }}>
                      <div className="testimonial-panel">
                        <div className="tes-quoteInfo">
                          <img
                            src="http://cryptomarkethtml.bdtask.com/assets/img/avatar-1.jpg"
                            className="quoteAvatar"
                            alt=""
                          />
                          <div>
                            <div className="quoteAuthor">
                              <span>Jahangir Alom</span>
                            </div>
                            <div className="quotePosition">
                              <span>
                                Co-founder of Bdtask, American business magnate
                              </span>
                            </div>
                            <div className="testimonial--rating">
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                            </div>
                          </div>
                        </div>
                        {/* /.testimonial-rating end */}
                        <div className="testimonial--body">
                          <p>
                            “ If you are going to use a passage of Lorem Ipsum, you
                            need to be sure there isn't anything embarrassing hidden
                            in the middle of text. ”
                          </p>
                        </div>
                        {/* /.testimonial-body end */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="owl-nav">
                  <div className="owl-prev">
                    <i className="fa fa-angle-left" />
                  </div>
                  <div className="owl-next">
                    <i className="fa fa-angle-right" />
                  </div>
                </div>
                <div className="owl-dots disabled" />
              </div>
            </div>
            <div className="col-sm-12">
              <div className="client-logo">
                <div className="logo-item">
                  <a href="#" target="_blank">
                    <img
                      src="http://cryptomarkethtml.bdtask.com/assets/img/brand-logo/01.png"
                      className="img-responsive"
                      alt="client-logo"
                    />
                  </a>
                </div>
                <div className="logo-item">
                  <a href="#" target="_blank">
                    <img
                      src="http://cryptomarkethtml.bdtask.com/assets/img/brand-logo/02.png"
                      className="img-responsive"
                      alt="client-logo"
                    />
                  </a>
                </div>
                <div className="logo-item">
                  <a href="#" target="_blank">
                    <img
                      src="http://cryptomarkethtml.bdtask.com/assets/img/brand-logo/03.png"
                      className="img-responsive"
                      alt="client-logo"
                    />
                  </a>
                </div>
                <div className="logo-item">
                  <a href="#" target="_blank">
                    <img
                      src="http://cryptomarkethtml.bdtask.com/assets/img/brand-logo/04.png"
                      className="img-responsive"
                      alt="client-logo"
                    />
                  </a>
                </div>
                <div className="logo-item">
                  <a href="#" target="_blank">
                    <img
                      src="http://cryptomarkethtml.bdtask.com/assets/img/brand-logo/05.png"
                      className="img-responsive"
                      alt="client-logo"
                    />
                  </a>
                </div>
                <div className="logo-item">
                  <a href="#" target="_blank">
                    <img
                      src="http://cryptomarkethtml.bdtask.com/assets/img/brand-logo/06.png"
                      className="img-responsive"
                      alt="client-logo"
                    />
                  </a>
                </div>
                <div className="logo-item">
                  <a href="#" target="_blank">
                    <img
                      src="http://cryptomarkethtml.bdtask.com/assets/img/brand-logo/07.png"
                      className="img-responsive"
                      alt="client-logo"
                    />
                  </a>
                </div>
                <div className="logo-item">
                  <a href="#" target="_blank">
                    <img
                      src="http://cryptomarkethtml.bdtask.com/assets/img/brand-logo/08.png"
                      className="img-responsive"
                      alt="client-logo"
                    />
                  </a>
                </div>
              </div>
              {/* /.End of client logo */}
            </div>
          </div>
        </div>
      </div>
      {/* /.End of testimonial content */}
      <div className="blog_content">
        <div className="container">
          <div className="row">
            <div className="col-sm-8 col-sm-offset-2">
              <div className="section_title">
                <h3>
                  Cryptocurrency <span>News</span>
                </h3>
                <p>
                  It is a long established fact that a reader will be distracted by
                  the readable content of a page when looking at its layout. The
                  point of using Lorem Ipsum.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="owl-blog owl-carousel owl-theme owl-loaded owl-drag">
              <div className="owl-stage-outer">
                <div
                  className="owl-stage"
                  style={{
                    transform: "translate3d(-1170px, 0px, 0px)",
                    transition: "all 0s ease 0s",
                    width: 4095
                  }}
                >
                  <div className="owl-item cloned" style={{ width: "292.5px" }}>
                    <div className="item">
                      <div className="post_grid">
                        <div className="grid_img">
                          <img
                            src="http://cryptomarkethtml.bdtask.com/assets/img/blog/360x250-3.jpg"
                            className="img-responsive"
                            alt=""
                          />
                        </div>
                        <div className="grid_body">
                          <h3 className="post_heading">
                            <a href="#">
                              <strong>IFusce</strong>{" "}
                              <span className="dash">—</span> ac tortor et lacus
                              volutpat euismod.
                            </a>
                          </h3>
                          <time dateTime="2018-01-21T19:00" className="time">
                            {" "}
                            13/02/17
                          </time>
                          <p>
                            Many desktop publishing packages and web page editors
                            now use Lorem Ipsum{" "}
                          </p>
                          {/*                                        , and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved 
                                                                        over the years, sometimes by accident, sometimes on purpose (injected humour and the like).*/}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="owl-item cloned" style={{ width: "292.5px" }}>
                    <div className="item">
                      <div className="post_grid">
                        <div className="grid_img">
                          <img
                            src="http://cryptomarkethtml.bdtask.com/assets/img/blog/360x250-4.jpg"
                            className="img-responsive"
                            alt=""
                          />
                        </div>
                        <div className="grid_body">
                          <h3 className="post_heading">
                            <a href="#">
                              <strong>IFusce</strong>{" "}
                              <span className="dash">—</span> ac tortor et lacus
                              volutpat euismod.
                            </a>
                          </h3>
                          <time dateTime="2018-01-21T19:00" className="time">
                            {" "}
                            13/02/17
                          </time>
                          <p>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry.
                          </p>
                          {/*                                    the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make
                                                                    a type specimen book.*/}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="owl-item cloned" style={{ width: "292.5px" }}>
                    <div className="item">
                      <div className="post_grid">
                        <div className="grid_img">
                          <img
                            src="http://cryptomarkethtml.bdtask.com/assets/img/blog/360x250-5.jpg"
                            className="img-responsive"
                            alt=""
                          />
                        </div>
                        <div className="grid_body">
                          <h3 className="post_heading">
                            <a href="#">
                              <strong>IFusce</strong>{" "}
                              <span className="dash">—</span> ac tortor et lacus
                              volutpat euismod.
                            </a>
                          </h3>
                          <time dateTime="2018-01-21T19:00" className="time">
                            {" "}
                            13/02/17
                          </time>
                          <p>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry.
                          </p>
                          {/*                                    the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make
                                                                    a type specimen book.*/}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="owl-item cloned" style={{ width: "292.5px" }}>
                    <div className="item">
                      <div className="post_grid">
                        <div className="grid_img">
                          <img
                            src="http://cryptomarkethtml.bdtask.com/assets/img/blog/360x250-6.jpg"
                            className="img-responsive"
                            alt=""
                          />
                        </div>
                        <div className="grid_body">
                          <h3 className="post_heading">
                            <a href="#">
                              <strong>IFusce</strong>{" "}
                              <span className="dash">—</span> ac tortor et lacus
                              volutpat euismod.
                            </a>
                          </h3>
                          <time dateTime="2018-01-21T19:00" className="time">
                            {" "}
                            13/02/17
                          </time>
                          <p>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry.
                          </p>
                          {/*                                    the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make
                                                                    a type specimen book.*/}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="owl-item active" style={{ width: "292.5px" }}>
                    <div className="item">
                      <div className="post_grid">
                        <div className="grid_img">
                          <img
                            src="http://cryptomarkethtml.bdtask.com/assets/img/blog/360x250-1.jpg"
                            className="img-responsive"
                            alt=""
                          />
                        </div>
                        <div className="grid_body">
                          <h3 className="post_heading">
                            <a href="#">
                              <strong>IFusce</strong>{" "}
                              <span className="dash">—</span> ac tortor et lacus
                              volutpat euismod.
                            </a>
                          </h3>
                          <time dateTime="2018-01-21T19:00" className="time">
                            {" "}
                            13/02/17
                          </time>
                          <p>
                            It is a long established fact that a reader will be
                            distracted by the readable content of a page
                          </p>
                          {/*                                        at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as 
                                                                        opposed to using 'Content here, content here', making it look like readable English.*/}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="owl-item active" style={{ width: "292.5px" }}>
                    <div className="item">
                      <div className="post_grid">
                        <div className="grid_img">
                          <img
                            src="http://cryptomarkethtml.bdtask.com/assets/img/blog/360x250-2.jpg"
                            className="img-responsive"
                            alt=""
                          />
                        </div>
                        <div className="grid_body">
                          <h3 className="post_heading">
                            <a href="#">
                              <strong>IFusce</strong>{" "}
                              <span className="dash">—</span> ac tortor et lacus
                              volutpat euismod.
                            </a>
                          </h3>
                          <time dateTime="2018-01-21T19:00" className="time">
                            {" "}
                            13/02/17
                          </time>
                          <p>
                            The standard chunk of Lorem Ipsum used since the 1500s
                            is reproduced below.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="owl-item active" style={{ width: "292.5px" }}>
                    <div className="item">
                      <div className="post_grid">
                        <div className="grid_img">
                          <img
                            src="http://cryptomarkethtml.bdtask.com/assets/img/blog/360x250-3.jpg"
                            className="img-responsive"
                            alt=""
                          />
                        </div>
                        <div className="grid_body">
                          <h3 className="post_heading">
                            <a href="#">
                              <strong>IFusce</strong>{" "}
                              <span className="dash">—</span> ac tortor et lacus
                              volutpat euismod.
                            </a>
                          </h3>
                          <time dateTime="2018-01-21T19:00" className="time">
                            {" "}
                            13/02/17
                          </time>
                          <p>
                            Many desktop publishing packages and web page editors
                            now use Lorem Ipsum{" "}
                          </p>
                          {/*                                        , and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved 
                                                                        over the years, sometimes by accident, sometimes on purpose (injected humour and the like).*/}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="owl-item active" style={{ width: "292.5px" }}>
                    <div className="item">
                      <div className="post_grid">
                        <div className="grid_img">
                          <img
                            src="http://cryptomarkethtml.bdtask.com/assets/img/blog/360x250-4.jpg"
                            className="img-responsive"
                            alt=""
                          />
                        </div>
                        <div className="grid_body">
                          <h3 className="post_heading">
                            <a href="#">
                              <strong>IFusce</strong>{" "}
                              <span className="dash">—</span> ac tortor et lacus
                              volutpat euismod.
                            </a>
                          </h3>
                          <time dateTime="2018-01-21T19:00" className="time">
                            {" "}
                            13/02/17
                          </time>
                          <p>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry.
                          </p>
                          {/*                                    the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make
                                                                    a type specimen book.*/}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="owl-item" style={{ width: "292.5px" }}>
                    <div className="item">
                      <div className="post_grid">
                        <div className="grid_img">
                          <img
                            src="http://cryptomarkethtml.bdtask.com/assets/img/blog/360x250-5.jpg"
                            className="img-responsive"
                            alt=""
                          />
                        </div>
                        <div className="grid_body">
                          <h3 className="post_heading">
                            <a href="#">
                              <strong>IFusce</strong>{" "}
                              <span className="dash">—</span> ac tortor et lacus
                              volutpat euismod.
                            </a>
                          </h3>
                          <time dateTime="2018-01-21T19:00" className="time">
                            {" "}
                            13/02/17
                          </time>
                          <p>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry.
                          </p>
                          {/*                                    the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make
                                                                    a type specimen book.*/}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="owl-item" style={{ width: "292.5px" }}>
                    <div className="item">
                      <div className="post_grid">
                        <div className="grid_img">
                          <img
                            src="http://cryptomarkethtml.bdtask.com/assets/img/blog/360x250-6.jpg"
                            className="img-responsive"
                            alt=""
                          />
                        </div>
                        <div className="grid_body">
                          <h3 className="post_heading">
                            <a href="#">
                              <strong>IFusce</strong>{" "}
                              <span className="dash">—</span> ac tortor et lacus
                              volutpat euismod.
                            </a>
                          </h3>
                          <time dateTime="2018-01-21T19:00" className="time">
                            {" "}
                            13/02/17
                          </time>
                          <p>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry.
                          </p>
                          {/*                                    the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make
                                                                    a type specimen book.*/}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="owl-item cloned" style={{ width: "292.5px" }}>
                    <div className="item">
                      <div className="post_grid">
                        <div className="grid_img">
                          <img
                            src="http://cryptomarkethtml.bdtask.com/assets/img/blog/360x250-1.jpg"
                            className="img-responsive"
                            alt=""
                          />
                        </div>
                        <div className="grid_body">
                          <h3 className="post_heading">
                            <a href="#">
                              <strong>IFusce</strong>{" "}
                              <span className="dash">—</span> ac tortor et lacus
                              volutpat euismod.
                            </a>
                          </h3>
                          <time dateTime="2018-01-21T19:00" className="time">
                            {" "}
                            13/02/17
                          </time>
                          <p>
                            It is a long established fact that a reader will be
                            distracted by the readable content of a page
                          </p>
                          {/*                                        at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as 
                                                                        opposed to using 'Content here, content here', making it look like readable English.*/}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="owl-item cloned" style={{ width: "292.5px" }}>
                    <div className="item">
                      <div className="post_grid">
                        <div className="grid_img">
                          <img
                            src="http://cryptomarkethtml.bdtask.com/assets/img/blog/360x250-2.jpg"
                            className="img-responsive"
                            alt=""
                          />
                        </div>
                        <div className="grid_body">
                          <h3 className="post_heading">
                            <a href="#">
                              <strong>IFusce</strong>{" "}
                              <span className="dash">—</span> ac tortor et lacus
                              volutpat euismod.
                            </a>
                          </h3>
                          <time dateTime="2018-01-21T19:00" className="time">
                            {" "}
                            13/02/17
                          </time>
                          <p>
                            The standard chunk of Lorem Ipsum used since the 1500s
                            is reproduced below.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="owl-item cloned" style={{ width: "292.5px" }}>
                    <div className="item">
                      <div className="post_grid">
                        <div className="grid_img">
                          <img
                            src="http://cryptomarkethtml.bdtask.com/assets/img/blog/360x250-3.jpg"
                            className="img-responsive"
                            alt=""
                          />
                        </div>
                        <div className="grid_body">
                          <h3 className="post_heading">
                            <a href="#">
                              <strong>IFusce</strong>{" "}
                              <span className="dash">—</span> ac tortor et lacus
                              volutpat euismod.
                            </a>
                          </h3>
                          <time dateTime="2018-01-21T19:00" className="time">
                            {" "}
                            13/02/17
                          </time>
                          <p>
                            Many desktop publishing packages and web page editors
                            now use Lorem Ipsum{" "}
                          </p>
                          {/*                                        , and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved 
                                                                        over the years, sometimes by accident, sometimes on purpose (injected humour and the like).*/}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="owl-item cloned" style={{ width: "292.5px" }}>
                    <div className="item">
                      <div className="post_grid">
                        <div className="grid_img">
                          <img
                            src="http://cryptomarkethtml.bdtask.com/assets/img/blog/360x250-4.jpg"
                            className="img-responsive"
                            alt=""
                          />
                        </div>
                        <div className="grid_body">
                          <h3 className="post_heading">
                            <a href="#">
                              <strong>IFusce</strong>{" "}
                              <span className="dash">—</span> ac tortor et lacus
                              volutpat euismod.
                            </a>
                          </h3>
                          <time dateTime="2018-01-21T19:00" className="time">
                            {" "}
                            13/02/17
                          </time>
                          <p>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry.
                          </p>
                          {/*                                    the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make
                                                                    a type specimen book.*/}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="owl-nav">
                <div className="owl-prev">
                  <i className="fa fa-angle-left" />
                </div>
                <div className="owl-next">
                  <i className="fa fa-angle-right" />
                </div>
              </div>
              <div className="owl-dots disabled" />
            </div>
          </div>
        </div>
      </div>
      {/* /.End of blog content */}
      <footer className="footer">
        <div className="footer-breadcrumbs">
          <div className="container">
            <div className="breadcrumbs-row">
              <ul className="f_breadcrumbs">
                <li>
                  <a href="#">
                    <span>Home</span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <span>About</span>
                  </a>
                </li>
              </ul>
              <div className="scroll-top" id="back-to-top">
                <div className="scroll-top-text">
                  <span>Scroll to Top</span>
                </div>
                <div className="scroll-top-icon">
                  <i className="fa fa-angle-up" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /.End of breadcrumbs */}
        <div className="action_btn_inner">
          <a href="register.html#tab1" className="action_btn">
            <span className="action_title">Register</span>
            <span className="lnr lnr-chevron-right action_icon" />
            <span className="action_sub_title">
              Join the new era of cryptocurrency exchange
            </span>
          </a>
          <a href="register.html#tab2" className="action_btn">
            <span className="action_title">Sign in</span>
            <span className="lnr lnr-chevron-right action_icon" />
            <span className="action_sub_title">
              Access the cryptocurrency experience you deserve
            </span>
          </a>
        </div>
        {/* /.End of action button */}
        <div className="main_footer">
          <div className="container">
            <div className="row">
              <div className="col-sm-4 col-md-4">
                <div className="widget-contact">
                  <ul className="list-icon">
                    <li>
                      <i className="fa fa-map-marker" /> 1355 Market Street, Suite
                      900
                      <br />
                      San Francisco, CA 94103
                    </li>
                    <li>
                      <i className="fa fa-phone" /> (123) 456-7890{" "}
                    </li>
                    <li>
                      <i className="fa fa-envelope" />{" "}
                      <a href="mailto:first.last@example.com">
                        first.last@example.com
                      </a>
                    </li>
                    <li>
                      <br />
                      <i className="fa fa-clock-o" />
                      Monday - Friday: <strong>08:00 - 22:00</strong>
                      <br />
                      Saturday, Sunday: <strong>Closed</strong>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-5 col-md-4 col-md-offset-1">
                <div className="row">
                  <div className="col-xs-6 col-sm-6 col-md-6">
                    <div className="footer-box">
                      <h3 className="footer-title">Our Company</h3>
                      <ul className="footer-list">
                        <li>
                          <a href="#">Customer support</a>
                        </li>
                        <li>
                          <a href="#">Help center</a>
                        </li>
                        <li>
                          <a href="#">Fees &amp; Limits</a>
                        </li>
                        <li>
                          <a href="#">Referral Program</a>
                        </li>
                        <li>
                          <a href="#">About Us</a>
                        </li>
                        <li>
                          <a href="#">24/7 Support</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-xs-6 col-sm-6 col-md-6">
                    <div className="footer-box">
                      <h3 className="footer-title">Service</h3>
                      <ul className="footer-list">
                        <li>
                          <a href="about.html">About Us</a>
                        </li>
                        <li>
                          <a href="service.html">Service</a>
                        </li>
                        <li>
                          <a href="exchange.html">Exchange Bitcoin</a>
                        </li>
                        <li>
                          <a href="blog.html">Blog news</a>
                        </li>
                        <li>
                          <a href="#">Our Team Member</a>
                        </li>
                        <li>
                          <a href="#">Roadmap Bitcoin</a>
                        </li>
                        <li>
                          <a href="contact.html">Contact us</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-3 col-md-3">
                <div className="newsletter-box">
                  <h3 className="footer-title">Email Newslatter</h3>
                  <p>Subscribe to our newsletter!</p>
                  <form className="newsletter-form" action="#" method="post">
                    <input type="hidden" name="form-name" defaultValue="form 4" />
                    <input placeholder="Email Address" type="text" />
                    <button type="submit">
                      <i className="fa fa-paper-plane" aria-hidden="true" />
                    </button>
                    <div className="envelope">
                      <i className="fa fa-envelope" aria-hidden="true" />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /.End of main footer */}
        <div className="sub_footer">
          <div className="container">
            <div className="logos-wrapper">
              <div className="logos-row">
                <div className="social-content">
                  <div className="social-row">
                    <div className="social_icon">
                      <a href="#" className="">
                        <i className="fa fa-facebook" />
                      </a>
                      <a href="#" className="">
                        <i className="fa fa-twitter" />
                      </a>
                      <a href="#" className="">
                        <i className="fa fa-instagram" />
                      </a>
                      <a href="#" className="">
                        <i className="fa fa-youtube-play" />
                      </a>
                      <a href="#" className="">
                        <i className="fa fa-linkedin" />
                      </a>
                    </div>
                    <div className="f-language">
                      <div className="btn-group bootstrap-select fit-width">
                        <button
                          type="button"
                          className="btn dropdown-toggle"
                          data-toggle="dropdown"
                          role="button"
                          title="English"
                        >
                          <span className="filter-option pull-left">
                            <span className="flag-icon flag-icon-us" /> English
                          </span>
                          &nbsp;
                          <span className="bs-caret">
                            <span className="caret" />
                          </span>
                        </button>
                        <div className="dropdown-menu open" role="combobox">
                          <ul
                            className="dropdown-menu inner"
                            role="listbox"
                            aria-expanded="false"
                            style={{ opacity: 1, display: "none" }}
                          >
                            <li data-original-index={0} className="selected">
                              <a
                                tabIndex={0}
                                className=""
                                data-tokens="null"
                                role="option"
                                aria-disabled="false"
                                aria-selected="true"
                              >
                                <span className="flag-icon flag-icon-us" /> English
                                <span className="glyphicon glyphicon-ok check-mark" />
                              </a>
                            </li>
                            <li data-original-index={1}>
                              <a
                                tabIndex={0}
                                className=""
                                data-tokens="null"
                                role="option"
                                aria-disabled="false"
                                aria-selected="false"
                              >
                                <span className="flag-icon flag-icon-mx" /> Español
                                <span className="glyphicon glyphicon-ok check-mark" />
                              </a>
                            </li>
                            <li data-original-index={2}>
                              <a
                                tabIndex={0}
                                className=""
                                data-tokens="null"
                                role="option"
                                aria-disabled="false"
                                aria-selected="false"
                              >
                                <span className="flag-icon flag-icon-bd" /> বাংলা
                                <span className="glyphicon glyphicon-ok check-mark" />
                              </a>
                            </li>
                          </ul>
                        </div>
                        <select
                          className="selectpicker"
                          data-width="fit"
                          tabIndex={-98}
                        >
                          <option data-content='<span class="flag-icon flag-icon-us"></span> English'>
                            English
                          </option>
                          <option data-content='<span class="flag-icon flag-icon-mx"></span> Español'>
                            Español
                          </option>
                          <option data-content='<span class="flag-icon flag-icon-bd"></span> বাংলা'>
                            বাংলা{" "}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="copyright">
                <span>Copyright Â© 2018. All rights reserved</span>
              </div>
            </div>
          </div>
        </div>
        {/* /.End of sub footer */}
      </footer>
      {/* /.End of footer */}
      {/* jQuery */}
    </div>

  );
};
export default Home;
