import React from 'react';
import './SuccessPage.css'

const SuccessPage = () => {
    return (
        <div className='col-lg-5 text-success payment-message'>
            <h3>Payment Successful</h3>
            <p>Your payment has been successfully processed.</p>
        </div>
    );
};

export default SuccessPage;
