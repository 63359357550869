import React, { useState } from 'react';
import { Input, Dropdown, Menu, Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import tsd from '../images/tsd.png'
const { Search } = Input;



function Drop() {
    const menu = (
        <Menu>
          <Menu.Item key="1" onClick={() => handleMenuClick("Tether USD (USDT)")}><img src={tsd} alt="Icon"  style={{width:'20px',height:'20px'}}/>Tether USD (USDT)</Menu.Item>
          <Menu.Item key="2" onClick={() => handleMenuClick("Tether USD (USDT-SC)")}><img src={tsd} alt="Icon"  style={{width:'20px',height:'20px'}}/>Tether USD (USDT-SC)</Menu.Item>
          <Menu.Item key="3" onClick={() => handleMenuClick("Tether USD (USDT-TRC20)")}><img src={tsd} alt="Icon"  style={{width:'20px',height:'20px'}}/>Tether USD (USDT-TRC20)</Menu.Item>
        </Menu>
      );
  const [selectedOption, setSelectedOption] = useState("");

  const handleMenuClick = (option) => {
    setSelectedOption(option);
  };

  return (
    <div>
      <div className='d-flex justify-content-between p-1' style={{border:'1px solid #00000054',borderRadius:'5px'}}>
        <Input placeholder="Enter text" style={{border:'1px solid white'}}/>
      
     
        <Dropdown overlay={menu} placement="bottomRight" >
          <Button style={{border:'1px solid white'}}>
          {selectedOption}<DownOutlined />
          </Button>
        </Dropdown>
      </div>
      <div>
        Selected option: {selectedOption}
      </div>
    </div>
  );
}

export default Drop;
