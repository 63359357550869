import React, { useState } from 'react';
import axios from 'axios';

const BankForm = () => {
  const [bankDetails, setBankDetails] = useState({
    bankName: '',
    bankAccountNumber: '',
    ifsc: '',
    upi: '',
    qrCode: null, // Update to null as initial value
  });

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    // If input type is file (for QR Code), update value to files[0]
    const updatedValue = name === 'qrCode' ? files[0] : value;
    setBankDetails({ ...bankDetails, [name]: updatedValue });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Create FormData object
      const formData = new FormData();
      // Append each field to FormData object
      Object.keys(bankDetails).forEach((key) => {
        formData.append(key, bankDetails[key]);
      });
      // Make POST request with FormData
      await axios.post('http://apextrade.pythonanywhere.com/bankdetails/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Specify content type as multipart/form-data for file uploads
        },
      });
      alert('Bank details submitted successfully!');
    } catch (error) {
      console.error('Error submitting bank details:', error);
      alert('Failed to submit bank details. Please try again.');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        Bank Name:
        <input type="text" name="bankName" value={bankDetails.bankName} onChange={handleInputChange} />
      </label>
      <label>
        Bank Account Number:
        <input type="text" name="bankAccountNumber" value={bankDetails.bankAccountNumber} onChange={handleInputChange} />
      </label>
      <label>
        IFSC Code:
        <input type="text" name="ifsc" value={bankDetails.ifsc} onChange={handleInputChange} />
      </label>
      <label>
        UPI ID:
        <input type="text" name="upi" value={bankDetails.upi} onChange={handleInputChange} />
      </label>
      <label>
        QR Code:
        <input type="file" name="qrCode" accept="image/*" onChange={handleInputChange} />
      </label>
      <button type="submit">Submit</button>
    </form>
  );
};

export default BankForm;
