import { Button, Checkbox, Form, Input, message } from 'antd';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
const Signup = () => {
  const [fileObj, setFileObj] = useState(null);
  const [fileObj1, setFileObj1] = useState(null);

  const onFinish = (values) => {


    const formData = new FormData();
    formData.append('userImage', fileObj);
    formData.append('username', values.username);
    formData.append('email', values.email);
    formData.append('password', values.password);
    formData.append('panimage', fileObj1);
    formData.append('aadahrImage', fileObj);
    fetch('https://apextrade.pythonanywhere.com/signup/', {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        // Show success message
        message.success('User registered successfully');
        // Redirect to login page after 3 seconds if redirect param is present
        if (true) {
          setTimeout(() => {
            window.location.href = '/login';
          }, 3000);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        // Show error message
        message.error('Error occurred while registering user');
      });
    // Add your fetch request here
    // ...
  };

  return (
    <center className=' p-2'>
      <div className='col-lg-4 mt-5 p-2' style={{ border: '1px solid #80808029', borderRadius: '5px', boxShadow: '0 1px 6px 0 rgba(32,33,36,.28)' }}>

        {/* Display user image here */}
        <div className="user-image d-flex flex-column align-items-center" style={{ margin: '10px', padding: '10px', border: '1px solid #ccc', borderRadius: '5px', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)' }}>
          <input type="file" accept="image/*" onChange={(e) => setFileObj(e.target.files[0])} className="text-center" style={{ width: '100%', maxWidth: '200px' }} />
          {fileObj && <img src={URL.createObjectURL(fileObj)} alt="User" style={{ width: '100px', height: '100px', borderRadius: '50%', marginTop: '20px' }} />}
          {!fileObj && <div className="empty-image mt-2">Upload User Image</div>}
        </div>




        <h3 className='mt-2'>Create Account</h3>

        <Form
          name="normal_login"
          className="login-form mt-3 p-2"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
          <Form.Item className='mt-3' name="username" rules={[{ required: true, message: 'Please input your Username!' }]}>
            <Input placeholder="Username" />
          </Form.Item>
          <Form.Item name="email" className='w-100' rules={[{ type: 'email' }]}>
            <Input placeholder='Enter your email' />
          </Form.Item>
          <Form.Item style={{ width: '100%' }} name="password" rules={[{ required: true, message: 'Please input your password!' }]}>
            <Input.Password placeholder='Enter your password' />
          </Form.Item>
          <div>
            <label>PAN Card</label>&nbsp;&nbsp;
            <input required className="mb-3" type="file" onChange={(e) => setFileObj(e.target.files[0])} />
          </div>
          <div>
            <label>Aadhar Card</label>&nbsp;&nbsp;
            <input required className="mb-3" type="file" onChange={(e) => setFileObj1(e.target.files[0])} />
          </div>
          <Checkbox className='mt-3 mb-3'>I agree to the <b>Terms & Conditions</b></Checkbox>
          <Form.Item>
            <Button type="primary" htmlType="submit">Register</Button>
            <p className='mt-2'>Already have an account? <a href="/login">Login</a></p>
          </Form.Item>
        </Form>
      </div>
    </center>
  );
};

export default Signup;
