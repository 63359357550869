import React, { useState, useEffect } from 'react';
import QRCode from 'qrcode.react';

function Qrcd() {
  const [bankDetails, setBankDetails] = useState(null); // State to hold bank details

  useEffect(() => {
    // Fetch bank details when component mounts
    fetch('https://apextrade.pythonanywhere.com/bankdetails/')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setBankDetails(data);
      })
      .catch(error => {
        console.error('Error fetching bank details:', error);
      });
  }, []); // Empty dependency array ensures this effect runs only once when the component mounts

  return (
    <div>
      {bankDetails && (
        <div>
          <div>
            Bank Name: {bankDetails.bankName}
          </div>
          <div>
            Bank Account Number: {bankDetails.bankAccountNumber}
          </div>
          <div>
            IFSC Code: {bankDetails.ifsc}
          </div>
          <div>
            UPI ID: {bankDetails.upi}
          </div>
          <div>
            <img src={`https://apextrade.pythonanywhere.com${bankDetails.qrCode}`} alt="QR Code" />
          </div>
        </div>
      )}
    </div>
  );
}

export default Qrcd;
