import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Mycom.css'; // Import your CSS file for styling

const MyComponent = () => {
  const [input1, setInput1] = useState('');
  const [input2, setInput2] = useState('');
  const [input3, setInput3] = useState('');
  const [data1, setData1] = useState('');
  const [data2, setData2] = useState('');
  const [data3, setData3] = useState('');
  const [setvalu, setvaluevalue] = useState(1);

  const handleInputChange = (e, setInput) => {
    setInput(e.target.value);
  };

  useEffect(() => {
    fetchData('https://apextrade.pythonanywhere.com/firstapi', setData1);
    fetchData('https://apextrade.pythonanywhere.com/secondapi', setData2);
    fetchData('https://apextrade.pythonanywhere.com/thirdapi', setData3);
  }, [setvalu]);

  const fetchData = async (apiEndpoint, setData) => {
    try {
      const response = await axios.get(apiEndpoint);
      setData(response.data.message);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (apiEndpoint, value) => {
    setvaluevalue(setvalu + 1);
    try {
      const formData = new FormData();
      formData.append('value', value);

      const response = await axios.post(apiEndpoint, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="container">
      <div className="input-row">
        <input type="text" value={input1} onChange={(e) => handleInputChange(e, setInput1)} />
        <button onClick={() => handleSubmit('https://apextrade.pythonanywhere.com/firstapi/', input1)}>Submit</button>
        <div>Tether USD (USDT): {data1}</div>
      </div>
      <div className="input-row">
        <input type="text" value={input2} onChange={(e) => handleInputChange(e, setInput2)} />
        <button onClick={() => handleSubmit('https://apextrade.pythonanywhere.com/secondapi/', input2)}>Submit</button>
        <div>Tether USD (USDT-SC): {data2}</div>
      </div>
      <div className="input-row">
        <input type="text" value={input3} onChange={(e) => handleInputChange(e, setInput3)} />
        <button onClick={() => handleSubmit('https://apextrade.pythonanywhere.com/thirdapi/', input3)}>Submit</button>
        <div>Tether USD (USDT-TRC20): {data3}</div>
      </div>
    </div>
  );
};

export default MyComponent;
