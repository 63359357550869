import { Button, Checkbox, Form, Input,message } from 'antd';
import React from 'react';

const Login = () => {
  
  const onFinish = (values) => {
    console.log('Received values of form: ', values);
    
    // Create a FormData object and append email and password
    var formdata = new FormData();
    formdata.append("email", values.email);
    formdata.append("password", values.password);

    // Define the request options
    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };

    // Send the POST request using fetch
    fetch("https://apextrade.pythonanywhere.com/login1/", requestOptions)
      .then(response => response.json()) // Parse response JSON
      .then(result => {
        message.success('User registered successfully');
        // Store the username in local storage
        localStorage.setItem('username', result.user.username);
        console.log(result);
        window.location.href = '/';
      })
      .catch(error => console.log('error', error));
  };
  
  return (
    <center className=' p-2'>
      <div className='col-lg-3 mt-5 p-2' style={{border:'1px solid #80808029',borderRadius:'5px',boxShadow: '0 1px 6px 0 rgba(32,33,36,.28)'}}>
        <h3 className='mt-2'>LOGIN</h3>
        <Form
          name="normal_login"
          className="login-form mt-3 p-2"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
          <Form.Item
            name="email"
            className=' w-100'
            rules={[
              {
                type: 'email',
              },
            ]}
          >
            <Input placeholder='Enter your email'/>
          </Form.Item>
          <Form.Item
            style={{width:'100%'}}
            name="password"
            rules={[
              {
                required: true,
                message: 'Please input your password!',
              },
            ]}
          >
            <Input.Password placeholder='Enter your password' />
          </Form.Item>
          <Form.Item>
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox>Remember me</Checkbox>
            </Form.Item>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" className="p-1 w-100  text-white" style={{border:'1px solid black',backgroundColor:'black',fontWeight:'bold'}}>
              Login
            </Button>
            Or <a href="/signup">register now!</a>
          </Form.Item>
        </Form>
      </div>
    </center>
  );
};

export default Login;
