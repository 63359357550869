import React, { useEffect, useState } from 'react';
import './Admin.css'; // Import your CSS file for styling
import MyComponent from '../usdtChange/MyComponent';
import axios from 'axios';
import BankForm from './Bank/BankForm';
const AdminPanel = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [viewData, setViewData] = useState('buy'); // Default view is set to 'buy'
  const [data, setData] = useState([]);
  const [user, setUser] = useState([]);

  useEffect(() => {
    fetchData();
  }, [viewData]);

  useEffect(() => {
    fetcUser();
  }, [viewData]);

  const fetchData = async () => {
    try {
      const response = await fetch(`https://apextrade.pythonanywhere.com/usdt/`);
      const jsonData = await response.json();
      setData(jsonData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetcUser = async () => {
    try {
      const response = await fetch(`https://apextrade.pythonanywhere.com/user/`);
      const jsonData = await response.json();
      setUser(jsonData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleLogin = () => {
    // Perform login verification here
    if (username === 'admin' && password === 'admin') {
      setLoggedIn(true);
    } else {
      alert('Invalid username or password');
    }
  };

  const handleLogout = () => {
    setLoggedIn(false);
    setUsername('');
    setPassword('');
  };

  const handleTabClick = (tab) => {
    setViewData(tab);
  };

  return (
    <div className="admin-panel">
      {!loggedIn ? (
        <div className="login-form">
          <input type="text" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} />
          <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
          <button onClick={handleLogin}>Login</button>
        </div>
      ) : (
        <div>
          <button className='btn btn-danger mb-4' onClick={handleLogout}>Logout</button>
          <div className="tabs">
            <button className={`tab ${viewData === 'buy' ? 'active' : ''}`} onClick={() => handleTabClick('buy')}>
              Buy
            </button>
            <button className={`tab ${viewData === 'sell' ? 'active' : ''}`} onClick={() => handleTabClick('sell')}>
              Sell
            </button>
            <button className={`tab ${viewData === 'user' ? 'active' : ''}`} onClick={() => handleTabClick('user')}>
              Users
            </button>
            <button className={`tab ${viewData === 'usdt' ? 'active' : ''}`} onClick={() => handleTabClick('usdt')}>
              ChangeUSD
            </button>
            <button className={`tab ${viewData === 'bank' ? 'active' : ''}`} onClick={() => handleTabClick('bank')}>
              bankDetails
            </button>
          </div>
          <div className="data-table">
            {viewData !== 'usdt' && viewData!=="bank"? <table>
              <thead>
                <tr>
                  <th>ID</th>
                  {viewData !== 'user' && <th>USD Value</th>}
                  {viewData === 'user' && <th>Name</th>}
                  {viewData === 'user' && <th>Email</th>}
                  <th>Image</th>
                  {viewData !== 'user' && <th>UsdtRecieved</th>}
                  {viewData !== '' && <th>Email</th>}
                </tr>
              </thead>
              <tbody>
                {viewData === 'buy' && data.map(item => (
                  item.cryptoType === 'buy' && (
                    <tr key={item.id}>
                      <td>{item.id}</td>
                      <td>{item.usdtValue}</td>
                      <td><img src={"https://apextrade.pythonanywhere.com/media/"+item.PaymentImage} alt={item.cryptoType} /></td>
                      <td>{item.UsdtRecieved}</td>
                      <td>{item.email}</td>
                    </tr>
                  )
                ))}
                {viewData === 'sell' && data.map(item => (
                  item.cryptoType === 'sell' && (
                    <tr key={item.id}>
                      <td>{item.id}</td>
                      <td>{item.usdtValue}</td>
                      <td><img src={"https://apextrade.pythonanywhere.com/media/"+item.PaymentImage} alt={item.cryptoType} /></td>
                      <td>{item.UsdtRecieved}</td>
                      <td>{item.email}</td>
                    </tr>
                  )
                ))}
                {viewData === 'user' && user.users.map(item => (
                  <tr key={item.id}>
                    <td>{item.id}</td>
                    <td>{item.username}</td>
                    <td>{item.email}</td>
                    <td><img src={"https://apextrade.pythonanywhere.com/media/"+`user${item.id}.jpg`} alt={item.username} /></td>
                  </tr>
                ))}
              </tbody>
            </table>:null}
           
            {viewData === 'usdt' && <MyComponent/>}
            {viewData === 'bank' && <BankForm/>}
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminPanel;
