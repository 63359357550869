import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link} from 'react-router-dom';


function Nav1() {
 
  return (
    <Navbar bg="light" expand="lg">
      <Container fluid>
        <Navbar.Brand href="/">Crypto</Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: '100px' }}
            navbarScroll
          > 
      <Link className="navLink mt-1" to="/">
          CryptoPage
      </Link>
           
            

          </Nav>
          <Form className="d-flex">
          <Link className="navLink mt-1" to="/login">
          <button style={{border:'2px solid black',borderRadius:'25px',width:'100px',color:'black',backgroundColor:'white',fontWeight:'bold',padding:'5px'}}>

          Login
          </button>
      </Link>
      <Link className="navLink mt-1" to="/signup">
        <button style={{border:'1px solid black',borderRadius:'25px',width:'100px',color:'white',backgroundColor:'black',fontWeight:'bold',padding:'5px'}}>
        Signup
        </button>
         
      </Link>
          
                 {/* <Link className="navLink mt-1" to="/">
                    Product
                </Link>
                <Link className="navLink mt-1" to="/cart">
                    Cart
                </Link>
                <span className="cartCount mt-1"><span><img src={cart} style={{width:'60px',height:'30px'}}/ ></span>Cart items: {items.length}</span> */}
            {/* <Form.Control
              type="search"
              placeholder="Search"
              className="me-2"
              aria-label="Search"
            />
            <Button variant="outline-success">Search</Button> */}
          </Form>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Nav1